<template>
  <q-page class="q-pa-md bg-grey-9">
    <div class="text-h5 w700i text-center q-py-lg q-mb-xl text-amber-7">
      Nombra tu equipo fantasy
    </div>
    <q-input
      label="Nombre de equipo"
      rounded
      standout="bg-amber-7"
      label-color="white"
      color="grey-6"
      dark
      class="q-mb-md"
      v-model="teamName"
    />
    <div class="text-body2 text-white q-mb-lg">
      Este sera el nombre que le asignarás a tu equipo.
    </div>

    <div class="row justify-center q-mb-xl">
      <q-btn
        label="Continuar"
        color="amber-7"
        rounded
        class="w700i q-px-lg"
        no-caps
        :disable="!teamName"
        @click="storeNewTeamName(teamName)"
      />
    </div>
    <BackToHome />
  </q-page>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BackToHome from "@/components/BackToHome";
export default {
  data() {
    return {
      teamName: "",
    };
  },
  methods: {
    ...mapActions("appStore", ["storeNewTeamName"]),
  },
  computed: {
    ...mapState("appStore", ["allFantasies", "newTeamData"]),
  },
  mounted() {
    if (this.allFantasies.length <= 0)
      this.$router.push(`/fantasy/${this.$route.params.fantasyId}`);
    if (this.newTeamData.name) this.teamName = this.newTeamData.name;
  },
  components: {
    BackToHome,
  },
};
</script>
