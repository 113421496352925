import { render, staticRenderFns } from "./Ranking.vue?vue&type=template&id=7888f7a4&"
import script from "./Ranking.vue?vue&type=script&lang=js&"
export * from "./Ranking.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QFab from 'quasar/src/components/fab/QFab.js';
import QFabAction from 'quasar/src/components/fab/QFabAction.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QBtn,QSpace,QIcon,QBtnToggle,QCard,QCardSection,QChip,QSeparator,QPageSticky,QFab,QFabAction,QDialog,QCardActions});
