<template>
  <q-page class="bg-grey-9 q-pa-md" v-if="$route.params.password == 'p455w0rd'">
    <q-select
      label="Seleccionar Fantasy"
      color="amber-7"
      class="q-mb-md"
      filled
      dark
      :options="fantasies"
      emit-value
      map-options
      v-model="selectedFantasy"
    />
    <q-card>
      <q-tabs
        v-model="tab"
        dense
        class="text-grey w700"
        active-color="amber-7"
        indicator-color="amber-7"
        align="justify"
        narrow-indicator
      >
        <q-tab name="teams" label="WINNING TEAMS" />
        <q-tab name="stats" label="PLAYERS STATS" />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="teams">
          <q-input
            type="textarea"
            color="amber-7"
            filled
            rows="25"
            class="q-mb-md"
            v-model="winningTeamsJson"
          />
          <q-btn
            label="Enviar"
            color="amber-7"
            push
            class="full-width w700"
            @click="postWinningTeamsJson()"
            :disable="!winningTeamsJson || !selectedFantasy"
          />
        </q-tab-panel>

        <q-tab-panel name="stats">
          <q-input
            type="textarea"
            color="amber-7"
            filled
            rows="25"
            class="q-mb-md"
            v-model="playersStatsJson"
          />
          <q-btn
            label="Enviar"
            color="amber-7"
            push
            class="full-width w700"
            @click="postPlayersStatsJson()"
            :disable="!playersStatsJson || !selectedFantasy"
          />
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </q-page>
</template>

<script>
import { mapActions } from "vuex";
import firebase from "firebase/app";
import "firebase/functions";

export default {
  data() {
    return {
      fantasies: [],
      selectedFantasy: "",
      tab: "teams",
      winningTeamsJson: "",
      playersStatsJson: "",
    };
  },
  methods: {
    ...mapActions("appStore", ["showHideLoading"]),

    async postWinningTeamsJson() {
      let data = JSON.parse(this.winningTeamsJson);
      // VERIFY Sheet1 EXISTS
      let dataExists = typeof data.Sheet1;
      if (dataExists == "undefined") {
        alert('Error en el objeto JSON. No se encontro KEY "Sheet1".');
        return;
      }
      //VERIFY RESULT VALUES ARE VALID
      let hasErr = false;
      for (let i of data.Sheet1) {
        if (i.result != "win" && i.result != "lose" && i.result != "draw") {
          hasErr = true;
        }
      }
      if (hasErr) {
        alert(
          'Error en resultado de equipo. Solo son permitidos los valores "win", "lose" o "draw".'
        );
        return;
      }
      //VERIFY TEAM VALUE IS NOT EMPTY
      hasErr = false;
      for (let i of data.Sheet1) {
        if (!i.team) {
          hasErr = true;
        }
      }
      if (hasErr) {
        alert("Error en ID de equipo.");
        return;
      }
      // ALL GOOD
      console.log("Sending Data");
      this.showHideLoading(true);
      const calculateWinningTeamsScore = firebase
        .functions()
        .httpsCallable("calculateWinningTeamsScore");
      let resp = await calculateWinningTeamsScore({
        fantasy: this.selectedFantasy,
        teamsData: data,
        date: Date.now(),
      });
      console.log(resp);
      if (resp.data == 1) {
        alert("Puntos agregados con exito.");
        this.winningTeamsJson = "";
      }
      this.showHideLoading(false);
    },
    async postPlayersStatsJson() {
      let data = JSON.parse(this.playersStatsJson);
      // VERIFY Sheet1 EXISTS
      let dataExists = typeof data.Sheet1;
      if (dataExists == "undefined") {
        alert('Error en el objeto JSON. No se encontro KEY "Sheet1".');
        return;
      }
      // VERIFY DATA
      let hasErr = false;
      // for (let i of data.Sheet1) {
      //   if (
      //     !i.player ||
      //     isNaN(parseInt(i.hit)) ||
      //     isNaN(parseInt(i.run)) ||
      //     isNaN(parseInt(i.homerun)) ||
      //     isNaN(parseInt(i.out)) ||
      //     isNaN(parseInt(i.strikeout))
      //   ) {
      //     hasErr = true;
      //   }
      // }
      if (hasErr) {
        alert("Hay un error en la data de estadisticas.");
        return;
      }
      // ALL GOOD
      console.log("Sending data");
      this.showHideLoading(true);
      const calculatePlayersStatsScore = firebase
        .functions()
        .httpsCallable("calculatePlayersStatsScore");
      let resp = await calculatePlayersStatsScore({
        fantasy: this.selectedFantasy,
        playersData: data,
        date: Date.now(),
      });
      console.log(resp);
      if (resp.data == 1) {
        alert("Puntos agregados con exito.");
        this.playersStatsJson = "";
      }
      this.showHideLoading(false);
    },
  },
  async mounted() {
    this.showHideLoading(true);
    let resp = await firebase
      .firestore()
      .collection("fantasies")
      .where("isActive", "==", true)
      .get();
    resp.forEach((fantasy) => {
      let f = {};
      f.value = fantasy.id;
      f.label = fantasy.data().name;
      this.fantasies.push(f);
    });
    this.showHideLoading(false);
    //
    // let allTeams = await firebase
    //   .firestore()
    //   .collection("teams")
    //   .where("leagueId", "==", "a13tvD6eByzgxC6yL9D1")
    //   .get();
    // allTeams.forEach((team) => {
    //   team.data().roster.forEach((player) => {
    //     if (player.id == "pcq_13") {
    //       console.log(team.data().name);
    //     }
    //   });
    // });
  },
};
</script>
