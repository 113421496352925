<template>
  <q-dialog
    v-model="loadingStatus"
    maximized
    transition-show="fade"
    transition-hide="fade"
  >
    <q-card class="bg-grey-9">
      <div class="absolute-center">
        <div class="row items-center q-mb-md">
          <img src="@/assets/DEM_White_PNG.png" width="90" />
          <div class="text-h5 w700i text-amber-7 q-ml-sm">Fantasy</div>
        </div>
        <div class="row justify-center">
          <q-spinner-dots color="white" size="4em" />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState("appStore", ["loadingStatus"]),
  },
};
</script>
