import Vue from "vue";
import Vuex from "vuex";

import appStore from "./appStore";
// import appStore from "./appStore";

Vue.use(Vuex);

export default function () {
  const Store = new Vuex.Store({
    modules: {
      appStore,
    },
    strict: process.env.DEV,
  });

  return Store;
}
