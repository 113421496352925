import { render, staticRenderFns } from "./FullTeamRosterSelector.vue?vue&type=template&id=57c95d94&"
import script from "./FullTeamRosterSelector.vue?vue&type=script&lang=js&"
export * from "./FullTeamRosterSelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QBtn,QAvatar,QInput,QCard,QList,QItem,QItemSection,QIcon,QDialog,QCardSection,QSpinnerDots});
