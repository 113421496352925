<template>
  <q-page class="q-pa-md bg-grey-9" v-if="fantasyData">
    <div class="row q-my-sm items-center" id="top">
      <q-btn
        label="Volver"
        flat
        icon="arrow_back"
        no-caps
        class="w700i"
        color="amber-7"
        rounded
        @click="$router.push(`/fantasy/${$route.params.fantasyId}`)"
      />
      <q-space />
      <div class="text-caption text-grey-6 text-right">
        Última act.
        <span class="w700i">{{ formatDate }}</span>
      </div>
    </div>

    <div class="row q-py-md items-center">
      <div class="text-h4 w700i text-white">
        <q-icon name="fas fa-trophy" color="amber-7" class="q-mr-md" />Ranking
      </div>
      <q-space />
      <div>
        <q-btn
          rounded
          icon="refresh"
          :color="canReload ? 'amber-7' : 'grey-6'"
          class="w700i"
          :disable="!canReload"
          @click="reloadData()"
          size="sm"
          unelevated
        />
      </div>
    </div>

    <div class="row q-mb-lg items-center">
      <q-btn-toggle
        v-model="sorting"
        push
        color="white"
        toggle-color="amber-7"
        text-color="black"
        size="sm"
        :options="[
          { label: 'Global', value: 'global' },
          { label: 'Semanal', value: 'weekly' },
        ]"
        v-if="fantasyData.rules.showWeeklyRanking"
      />
      <q-space />
      <q-btn
        color="amber-7"
        label="¿Cómo suman los puntos?"
        class="w700"
        no-caps
        size="sm"
        rounded
        flat
        @click="pointsDialog = true"
      />
    </div>

    <template v-if="allTeams.length >= 1">
      <q-card
        :class="returnClass(i)"
        flat
        v-for="(team, i) in sorting == 'global'
          ? sortTeamsByGlobalScore
          : sortTeamsByWeekScore"
        :key="i"
        :id="team.id"
        style="border-radius: 20px;"
        @click="
          $router.push(`/team/${$route.params.fantasyId}/${team.id}/${i + 1}`)
        "
      >
        <q-card-section>
          <div :class="`text-${returnTextSize(i)} w700i`">
            {{ i + 1 }}. {{ team.name }}
            <q-icon
              name="person"
              size="xs"
              v-if="userData && iAmInThisTeam(team.roster)"
            />
            <q-chip
              icon="star"
              :color="i > 2 ? 'grey-3' : 'grey-9'"
              :text-color="i > 2 ? 'black' : 'white'"
              size="sm"
              v-if="userData && team.owner == userData.id"
              >Tu equipo</q-chip
            >
          </div>
        </q-card-section>
        <q-separator dark />
        <q-card-section>
          <div class="text-caption">
            {{ sorting == "global" ? team.score : team.weekScore }} pts.
          </div>
        </q-card-section>
      </q-card>
    </template>
    <div
      class="absolute-center text-h5 text-grey-7 full-width text-center w700i"
      v-else
    >
      Aun no hay equipos registrados.
    </div>
    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-fab
        v-model="floatingAction"
        external-label
        color="amber-7"
        icon="keyboard_arrow_up"
        direction="up"
      >
        <q-fab-action
          label-class="bg-grey-3 text-grey-8"
          external-label
          label-position="left"
          color="grey-9"
          icon="emoji_events"
          label="Ir a mi equipo"
          text-color="amber-7"
          @click="scrollToTeam()"
        />
        <q-fab-action
          label-class="bg-grey-3 text-grey-8"
          external-label
          label-position="left"
          color="grey-9"
          icon="arrow_upward"
          text-color="amber-7"
          label="Volver arriba"
          @click="scrollToTeam('top')"
        />
      </q-fab>
    </q-page-sticky>
    <q-dialog v-model="pointsDialog">
      <q-card
        class="df-font grey-3"
        style="border-radius: 20px;width: 700px; max-width: 80vw;"
      >
        <q-card-section>
          <div class="text-h5 w700i text-amber-7">
            ¿Cómo suman los puntos?
          </div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="row q-mb-md">
            <div class="col-6">
              <div class="text-subtitle2 w700i">Estadística</div>
            </div>
            <div class="col">
              <div class="text-subtitle2 w700i">Tipo</div>
            </div>
            <div class="col-2">
              <div class="text-subtitle2 w700i text-center">Puntos</div>
            </div>
          </div>
          <div
            class="row q-mb-xs"
            v-for="(el, i) in fantasyData.calculateStats"
            :key="i"
          >
            <div class="col-6">
              <div class="text-subtitle2" style="text-transform: capitalize;">
                {{ el.stat }}
              </div>
            </div>
            <div class="col" style="text-transform: capitalize;">
              {{ returnTypeName(el.type) }}
            </div>
            <div class="col-2 text-center">{{ el.points }}</div>
          </div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="text-body2 q-mb-sm">
            Las estadísticas cuyo rol coincida con el rol de los jugadores
            sumarán el doble de puntos.
          </div>
          <div
            class="text-body2 q-mb-sm"
            v-if="fantasyData.rules.captainSelect"
          >
            El jugador asignado como <span class="w700">capitán</span> sumará
            doble para todas las estadísticas, y cuatro veces si además coincidé
            con el rol de la estadística.
          </div>
          <div class="text-body2" v-if="fantasyData.rules.rookieModifier">
            Si tu jugador es <span class="w700">Rookie</span>, esto hara que
            sume doble puntaje.
          </div>
        </q-card-section>
        <q-separator />
        <q-card-actions class="q-py-sm" align="center">
          <q-btn
            label="Aceptar"
            color="amber-7"
            rounded
            class="w700 q-px-xl"
            unelevated
            size="sm"
            @click="pointsDialog = false"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { mapState, mapActions } from "vuex";

import firebase from "firebase/app";
import "firebase/firebase-auth";

import moment from "moment";

export default {
  data() {
    return {
      canReload: true,
      fantasyData: null,
      sorting: "global",
      pointsDialog: false,
      floatingAction: false,
    };
  },
  methods: {
    ...mapActions("appStore", ["getAllTeams"]),

    reloadData() {
      this.canReload = false;
      this.getAllTeams();
      setTimeout(() => {
        this.canReload = true;
      }, 30000);
    },

    returnTextSize(index) {
      switch (index) {
        case 0:
          return "h4";
        case 1:
          return "h6";
        case 2:
          return "h6";
        default:
          return "subtitle2";
      }
    },
    returnClass(index) {
      switch (index) {
        case 0:
          return "q-mb-md bg-amber-7 text-white";
        case 1:
          return "q-mb-md bg-grey-3 text-grey-10";
        case 2:
          return "q-mb-md bg-grey-3 text-grey-10";
        default:
          return "q-mb-md bg-grey-10 text-grey-3";
      }
    },
    returnTypeName(type) {
      if (type == "ofense") return "Ofensa";
      if (type == "defense") return "Defensa";
      if (type == "pitcher") return "Pitcher";
      else return type;
    },
    scrollToTeam(id) {
      if (id) {
        let el = document.getElementById(id);
        this.$smoothScroll({
          scrollTo: el,
        });
      } else {
        try {
          let team = this.currentUserTeam.find(
            (team) => team.leagueId == this.$route.params.fantasyId
          );
          let el = document.getElementById(team.id);
          this.$smoothScroll({
            scrollTo: el,
          });
        } catch (e) {
          console.log("User does not have team in this fantasy");
        }
      }
      //
    },
    iAmInThisTeam(players) {
      try {
        let myIdInThisLeague = this.userData.iAm.find(
          (player) => player.leagueId == this.$route.params.fantasyId
        );
        myIdInThisLeague = myIdInThisLeague.id;
        let exist = players.find((player) => player.id == myIdInThisLeague);
        return exist;
      } catch (e) {
        return false;
      }
    },
  },
  computed: {
    ...mapState("appStore", [
      "userData",
      "allTeams",
      "allFantasies",
      "currentUserTeam",
    ]),

    formatDate() {
      return moment(this.fantasyData.lastUpdate)
        .locale("es")
        .format("ddd DD MMM YYYY hh:mm a");
    },
    sortTeamsByGlobalScore() {
      let sorted = this.allTeams.sort((a, b) => {
        return a.score - b.score;
      });
      return sorted.reverse();
    },
    sortTeamsByWeekScore() {
      let sorted = this.allTeams.sort((a, b) => {
        return a.weekScore - b.weekScore;
      });
      return sorted.reverse();
    },
  },
  async mounted() {
    this.getAllTeams();
    if (this.allFantasies.length > 0) {
      this.fantasyData = this.allFantasies.find(
        (fantasy) => fantasy.id == this.$route.params.fantasyId
      );
    } else {
      let resp = await firebase
        .firestore()
        .collection("fantasies")
        .doc(this.$route.params.fantasyId)
        .get();
      this.fantasyData = resp.data();
    }

    try {
      setTimeout(() => {
        this.scrollToTeam();
      }, 1000);
    } catch (e) {}
  },
};
</script>
