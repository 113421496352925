<template>
  <q-page class="q-pa-md bg-grey-9" v-if="team && fantasyData">
    <q-btn
      label="Volver"
      flat
      icon="arrow_back"
      no-caps
      class="w700i q-my-md"
      color="amber-7"
      rounded
      :to="`/fantasy/${$route.params.fantasyId}`"
    />
    <div class="row justify-center q-mt-md q-mb-md">
      <q-avatar class="bg-white" size="10em">
        <q-img
          v-if="team.mainTeam.logo.attributes"
          :src="team.mainTeam.logo.attributes.url"
          style="width: 150px;"
        />
        <q-img v-else :src="team.mainTeam.logo" style="width: 150px;" />
      </q-avatar>
    </div>
    <div class="text-h4 text-center q-mb-lg w700i text-amber-7">
      <span class="text-white text-h5 w700i">Puntos:</span>
      {{ team.score }}
    </div>
    <div class="text-h4 w700i text-center q-mb-lg text-white">
      {{ team.name }}<br /><span></span>
    </div>
    <!-- <div class="row">
      <div class="col">
        <div class="text-h6 q-mb-lg text-white w700i ">
          <span class="text-amber-7">Entrenador:</span><br />{{
            team.ownerName
          }}
        </div>
      </div>
      <div class="col">
        <div class="text-h6 text-white w700i ">
          <span class="w700i text-amber-7">Posicion en ranking:</span><br />#4
        </div>
      </div>
    </div> -->

    <div class="text-h5 w700i q-mb-md text-amber-7">
      Roster
    </div>
    <q-list bordered separator class="bg-white" style="border-radius: 20px;">
      <q-item v-for="player in team.roster" :key="player.id">
        <q-item-section avatar v-if="player.profile">
          <q-avatar>
            <img :src="player.profile" />
          </q-avatar>
        </q-item-section>
        <q-item-section>
          <div>
            <span
              class="w700i text-amber-7"
              v-if="player.isCaptain && fantasyData.rules.captainSelect"
              >(C)</span
            >
            <span class="w700i">
              {{ player.name }}
            </span>
            <q-icon
              name="male"
              size="1.2em"
              class="q-mr-xs"
              color="blue-6"
              v-if="player.gender == 'm' && fantasyData.rules.isGenderMix"
            />
            <q-icon
              name="female"
              size="1.2em"
              class="q-mr-xs"
              color="pink-6"
              v-if="player.gender == 'f' && fantasyData.rules.isGenderMix"
            />
            <span
              class="text-caption w700i text-amber-7"
              v-if="player.isRookie && fantasyData.rules.rookieModifier"
              >Rookie</span
            >
          </div>
          <div class="w700i text-caption text-grey-9">
            ({{ player.teamName }})
          </div>
        </q-item-section>
        <q-item-section side>
          <q-item-label caption>{{
            returnRoleLabel(player.role).label.toUpperCase()
          }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-page>
</template>

<script>
import { mapState, mapActions } from "vuex";

import firebase from "firebase/app";
import "firebase/firebase-auth";

export default {
  data() {
    return {
      team: null,
      fantasyData: null,
    };
  },
  methods: {
    ...mapActions("appStore", ["showHideLoading"]),

    returnRoleLabel(role) {
      return this.fantasyData.roles.find((r) => r.value == role);
    },
  },
  computed: {
    ...mapState("appStore", ["currentUserTeam", "allFantasies"]),
  },
  async mounted() {
    this.showHideLoading(true);
    if (this.currentUserTeam) {
      this.team = this.currentUserTeam.find(
        (team) => team.leagueId == this.$route.params.fantasyId
      );
    }
    if (!this.team) {
      let team = await firebase
        .firestore()
        .collection("teams")
        .where("leagueId", "==", this.$route.params.fantasyId)
        .where("owner", "==", firebase.auth().currentUser.uid)
        .get();
      team.forEach((t) => {
        this.team = t.data();
      });
    }
    if (this.allFantasies.length > 0) {
      this.fantasyData = this.allFantasies.find(
        (fantasy) => fantasy.id == this.$route.params.fantasyId
      );
    } else {
      let resp = await firebase
        .firestore()
        .collection("fantasies")
        .doc(this.$route.params.fantasyId)
        .get();
      this.fantasyData = resp.data();
    }
    this.showHideLoading(false);
  },
};
</script>
