<template>
  <q-page class="q-pa-md bg-grey-9">
    <q-btn
      label="Volver"
      flat
      icon="arrow_back"
      no-caps
      class="w700i q-my-sm"
      color="amber-7"
      rounded
      @click="$router.back()"
    />
    <div class="text-h5 text-amber-7 w700i text-center q-my-md">
      Soy jugador
    </div>
    <div class="text-body2 text-white q-mb-lg">
      Si eres jugador de alguna de las ligas, puedes seleccionarte del listado
      de abajo y podrás ver que equipos te han seleccionado para su roster.
    </div>
    <q-select
      label="Liga"
      dark
      color="amber-7"
      :options="activeFantasies"
      v-model="selectedLeague"
      filled
      class="q-mb-lg"
      emit-value
      map-options
    />
    <q-select
      label="Equipo"
      dark
      color="amber-7"
      :options="teams"
      v-model="selectedTeam"
      filled
      class="q-mb-lg"
      emit-value
      map-options
      v-if="selectedLeague && !loading"
    />
    <q-list
      bordered
      class="bg-white"
      style="border-radius: 20px;"
      v-if="selectedTeam && !iAm && !loading"
    >
      <q-item
        clickable
        v-ripple
        v-for="player in players"
        :key="player.id"
        @click="iAm = player"
      >
        <q-item-section avatar v-if="player.profile">
          <q-avatar>
            <img :src="player.profile" />
          </q-avatar>
        </q-item-section>
        <q-item-section>{{ player.name }}</q-item-section>
      </q-item>
    </q-list>
    <div class="row justify-center" v-if="loading">
      <q-spinner-dots color="amber-7" size="2.5em" />
    </div>
    <q-card style="border-radius: 20px;" v-if="iAm">
      <q-card-section class="row justify-center" v-if="iAm.profile">
        <img :src="iAm.profile" width="150" style="border-radius: 20px;" />
      </q-card-section>
      <q-card-section>
        <div class="text-h5 w700i text-grey-9 text-center">
          {{ iAm.name }}
        </div>
      </q-card-section>
      <template v-if="!alreadyInThisLeague">
        <q-card-actions align="center">
          <q-btn
            label="Confirmar"
            rounded
            push
            color="amber-7"
            class="full-width w700i"
            no-caps
            @click="confirmIAmThisPlayer()"
          />
        </q-card-actions>
        <q-card-actions>
          <q-btn
            label="Seleccionar otro jugador"
            rounded
            outline
            color="amber-7"
            class="full-width w700i"
            no-caps
            @click="iAm = null"
          />
        </q-card-actions>
        <q-card-actions>
          <q-btn
            label="Cancelar"
            rounded
            flat
            color="red-7"
            class="full-width w700i"
            no-caps
            to="/profile"
          />
        </q-card-actions>
      </template>
      <q-card-section v-else>
        <div class="text-subtitle2 text-center text-red-7 w700">
          Lo sentimos, ya has seleccionado un jugador en esta liga.
        </div>
      </q-card-section>
    </q-card>
  </q-page>
</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";

import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      activeFantasies: [],
      selectedLeague: null,
      jsonTeams: null,
      teams: [],
      selectedTeam: null,
      iAm: null,
      players: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions("appStore", ["addIAm"]),
    async getOpenFantasies() {
      let resp = await firebase
        .firestore()
        .collection("fantasies")
        .where("isActive", "==", true)
        .get();
      let active = [];
      resp.forEach((fantasy) => {
        let f = {
          label: "",
          value: "",
        };
        f.label = fantasy.data().name;
        f.value = fantasy.data();
        f.value.id = fantasy.id;
        active.push(f);
      });
      this.activeFantasies = active;
    },
    async loadTeamsJson(path) {
      if (this.jsonIsInFirebase) {
        this.loading = true;
        let allTeams = [];
        const getTeams = firebase.functions().httpsCallable("getTeamsJson");
        let teams = await getTeams(this.selectedLeague.jsonTeams);
        teams.data.forEach((team) => {
          allTeams.push({
            label: team.name,
            value: team.id,
          });
        });
        this.teams = allTeams;
        this.loading = false;
      } else {
        let jsonTeams = require(`@/assets/${path}`).teams;
        this.jsonTeams = jsonTeams;
        let teams = [];
        for (let i in jsonTeams) {
          let team = {
            label: "",
            value: "",
          };
          team.label = jsonTeams[i].name;
          team.value = i;
          teams.push(team);
        }
        this.teams = teams;
      }
    },
    confirmIAmThisPlayer() {
      let iAmObject = {
        id: this.iAm.id,
        name: this.iAm.name,
        profile: this.iAm.profile ? this.iAm.profile : "",
        teamId: this.selectedTeam,
        leagueId: this.selectedLeague.id,
        leagueName: this.selectedLeague.name,
        leagueLogo: this.selectedLeague.logo,
      };
      this.addIAm(iAmObject);
    },
    async requestPlayersJson() {
      this.loading = true;
      let params = {
        url: this.selectedLeague.jsonTeams,
        teamId: this.selectedTeam,
      };
      const getRoster = firebase.functions().httpsCallable("getRosterJson");
      let roster = await getRoster(params);
      this.players = roster.data.players;
      this.loading = false;
    },
  },
  computed: {
    ...mapState("appStore", ["userData"]),

    alreadyInThisLeague() {
      if (!this.userData.iAm) return false;
      else {
        let exists = this.userData.iAm.find(
          (player) => player.leagueId == this.selectedLeague.id
        );
        if (exists) return true;
        else return false;
      }
    },

    jsonIsInFirebase() {
      return this.selectedLeague.jsonTeams.includes("firebase");
    },
  },
  mounted() {
    this.getOpenFantasies();
  },
  watch: {
    selectedLeague: function() {
      this.loadTeamsJson(this.selectedLeague.jsonTeams);
      this.selectedTeam = null;
    },
    selectedTeam: function() {
      if (this.jsonIsInFirebase) this.requestPlayersJson();
      else this.players = this.jsonTeams[this.selectedTeam].players;
    },
  },
};
</script>
