import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import FantasyHome from "@/views/FantasyHome";
import TeamNameSelection from "@/views/TeamNameSelection";
import OficialTeamsList from "@/views/OficialTeamsList";
import Ranking from "@/views/Ranking";
import MyTeam from "@/views/MyTeam";
import Register from "@/views/Register";
import FullTeamRosterSelector from "@/views/FullTeamRosterSelector";
import MainTeamSelect from "@/views/MainTeamSelect";
import FantasyTeamCreation from "@/views/FantasyTeamCreation";
import Profile from "@/views/Profile";
import Login from "@/views/Login";
import ViewTeam from "@/views/ViewTeam";
import PasswordReset from "@/views/PasswordReset";
import AdminDashboard from "@/views/AdminDashboard";
import InstallOsSelect from "@/views/InstallOsSelect";
import IphoneInstall from "@/views/IphoneInstall";
import AndroidInstall from "@/views/AndroidInstall";
import WipeTeams from "@/views/WipeTeams";
import UserPlaysInLeague from "@/views/UserPlaysInLeague";

//FIREBASE
import firebase from "firebase/app";
import "firebase/firebase-auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/fantasy/:fantasyId/:reload?",
    name: "FantasyHome",
    component: FantasyHome,
  },
  {
    path: "/team-name-select/:fantasyId",
    name: "TeamNameSelection",
    component: TeamNameSelection,
  },
  {
    path: "/oficial-teams-list/:fantasyId",
    name: "OficialTeamsList",
    component: OficialTeamsList,
  },
  {
    path: "/ranking/:fantasyId",
    name: "Ranking",
    component: Ranking,
  },
  {
    path: "/team/:fantasyId/:teamId/:position?",
    name: "ViewTeam",
    component: ViewTeam,
  },
  {
    path: "/my-team/:fantasyId",
    name: "MyTeam",
    component: MyTeam,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: PasswordReset,
  },
  {
    path: "/team-roster-selector/:fantasyId/:teamId",
    name: "FullTeamRosterSelector",
    component: FullTeamRosterSelector,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/main-team-select/:fantasyId",
    name: "MainTeamSelect",
    component: MainTeamSelect,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fantasy-team-creation/:fantasyId/:resp",
    name: "FantasyTeamCreation",
    component: FantasyTeamCreation,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user-plays-in-league",
    name: "UserPlaysInLeague",
    component: UserPlaysInLeague,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/admin/:password",
    name: "AdminDashboard",
    component: AdminDashboard,
  },
  {
    path: "/wipe/:password",
    name: "WipeTeams",
    component: WipeTeams,
  },
  {
    path: "/install-select",
    name: "InstallOsSelect",
    component: InstallOsSelect,
  },
  {
    path: "/install-ios",
    name: "IphoneInstall",
    component: IphoneInstall,
  },
  {
    path: "/install-android",
    name: "AndroidInstall",
    component: AndroidInstall,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) next();
      else next("/login");
    });
  } else {
    next();
  }
});

export default router;
