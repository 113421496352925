<template>
  <q-page class="q-pa-md img-bg img-main ">
    <!-- <div class="text-h4 text-center df-font w700i text-amber-7 q-py-md q-mb-xl">
      Bienvenido
    </div> -->
    <div class="row justify-center q-my-md">
      <div class="col-3">
        <img src="@/assets/df-logo.png" width="100%" />
      </div>
    </div>
    <div class="text-h6 w700i text-amber-7 q-mb-sm">
      Activos
    </div>
    <q-card
      v-for="fantasy in filterFantasiesByStatus(true)"
      :key="fantasy.id"
      @click="$router.push(`/fantasy/${fantasy.id}`)"
      class="q-mb-sm"
      style="border-radius: 20px;"
    >
      <q-card-section>
        <div class="row items-center">
          <div class="col-2">
            <q-avatar size="4.5em">
              <img :src="fantasy.logo" />
            </q-avatar>
          </div>
          <div class="col-9 q-pl-md">
            <div class="text-h6 df-font w700i">
              {{ fantasy.name }}
            </div>
          </div>
        </div>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right" class="bg-grey-2">
        <q-btn
          label="Acceder"
          icon-right="navigate_next"
          flat
          class="df-font w400i"
          no-caps
        />
      </q-card-actions>
    </q-card>
    <div class="text-h6 w700i text-amber-7 q-mb-sm q-mt-xl">
      Culminados
    </div>
    <q-card
      v-for="fantasy in filterFantasiesByStatus(false)"
      :key="fantasy.id"
      @click="$router.push(`/fantasy/${fantasy.id}`)"
      class="q-mb-sm"
      style="border-radius: 20px;"
    >
      <q-card-section>
        <div class="row items-center">
          <div class="col-2">
            <q-avatar size="4.5em">
              <img :src="fantasy.logo" />
            </q-avatar>
          </div>
          <div class="col-9 q-pl-md">
            <div class="text-h6 df-font w700i">
              {{ fantasy.name }}
            </div>
          </div>
        </div>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right" class="bg-grey-2">
        <q-btn
          label="Acceder"
          icon-right="navigate_next"
          flat
          class="df-font w400i"
          no-caps
        />
      </q-card-actions>
    </q-card>
  </q-page>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("appStore", ["getAllFantasies"]),

    filterFantasiesByStatus(status) {
      let fantasies = this.allFantasies.filter(
        (fantasy) => fantasy.isActive == status
      );
      return fantasies.sort((a, b) => b.lastUpdate - a.lastUpdate);
    },
  },
  computed: {
    ...mapState("appStore", ["allFantasies"]),
  },
  mounted() {
    this.getAllFantasies();
  },
};
</script>
