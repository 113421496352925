<template>
  <q-page class="bg-grey-9 q-pa-md">
    <div v-if="team">
      <q-btn
        icon="arrow_back"
        label="Volver a listado de equipos"
        color="amber-7"
        class="df-font w700 q-mb-lg"
        size="sm"
        rounded
        push
        @click="$router.back()"
      />
      <div class="row justify-center q-my-md">
        <q-avatar size="8em" class="bg-white">
          <img v-if="team.logo.attributes" :src="team.logo.attributes.url" />
          <img v-else :src="team.logo" />
        </q-avatar>
      </div>
      <div class="text-h5 text-center w700i text-amber-7 q-mb-lg">
        {{ team.name }}
      </div>

      <q-input
        label="Buscar jugador"
        v-model="searchFilter"
        rounded
        standout="bg-amber-7"
        label-color="white"
        color="grey-6"
        dark
        class="q-mb-lg"
      />
      <q-card style="border-radius: 20px">
        <q-list>
          <q-item v-for="player in filterPlayersBySearch" :key="player.id">
            <q-item-section avatar v-if="player.profile">
              <q-avatar @click="previewPlayer(player)">
                <img :src="player.profile" />
              </q-avatar>
            </q-item-section>
            <q-item-section>
              <div class="row items-center">
                <template v-if="fantasyData.rules.isGenderMix">
                  <q-icon
                    name="male"
                    size="1.5em"
                    class="q-mr-sm"
                    color="blue-6"
                    v-if="player.gender == 'm'"
                  />
                  <q-icon
                    name="female"
                    size="1.5em"
                    class="q-mr-sm"
                    color="pink-6"
                    v-if="player.gender == 'f'"
                  />
                </template>

                <span>{{ returnPlayerName(player.name) }}</span
                >&nbsp;
                <span
                  class="text-caption text-amber-7 w700i"
                  v-if="player.isRookie && fantasyData.rules.rookieModifier"
                >
                  Rookie</span
                >
              </div>
            </q-item-section>
            <q-item-section avatar>
              <q-btn
                icon="add"
                round
                unelevated
                color="amber-7"
                size="xs"
                @click="
                  addPlayerToNewTeamRoster({
                    player: player,
                    team: $route.params.teamId,
                    teamName: team.name,
                    logo: team.logo,
                  })
                "
                v-if="playerExistsInRoster(player)"
              />
              <q-btn
                icon="remove"
                round
                unelevated
                color="red-7"
                size="xs"
                @click="removePlayerToNewTeamRoster(player)"
                v-else
              />
            </q-item-section>
          </q-item>
        </q-list>
      </q-card>
      <div class="row q-mt-md">
        <q-btn
          label="Volver"
          icon="arrow_back"
          rounded
          color="amber-7"
          class="w700"
          size="sm"
          push
          @click="$router.back()"
        />
      </div>
      <q-dialog
        v-model="playerPreviewDialog"
        transition-show="jump-down"
        transition-hide="fade"
      >
        <q-card
          style="border-radius: 20px; width: 700px; max-width: 80vw;"
          v-if="playerPreview"
        >
          <!-- <q-card-section class="q-pa-none"> -->
          <img :src="playerPreview.profile" width="100%" />
          <!-- </q-card-section> -->
          <!-- <q-card-section>
          <div class="text-h5 w700 text-amber-7 text-center">
            {{ playerPreview.name }}
          </div>
        </q-card-section> -->
        </q-card>
      </q-dialog>
      <BackToHome />
    </div>
    <div class="absolute-center text-center" v-else>
      <div class="text-h6 df-font w700i text-white">Cargando roster...</div>
      <q-spinner-dots color="amber-7" size="4em" />
    </div>
  </q-page>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BackToHome from "@/components/BackToHome";

import firebase from "firebase/app";
import "firebase/functions";

export default {
  data() {
    return {
      fantasyData: null,
      team: null,
      searchFilter: "",
      playerPreviewDialog: false,
      playerPreview: null,
    };
  },
  methods: {
    ...mapActions("appStore", [
      "addPlayerToNewTeamRoster",
      "removePlayerToNewTeamRoster",
    ]),

    playerExistsInRoster(p) {
      if (!this.newTeamData.roster.find((player) => player.id == p.id))
        return true;
    },
    returnPlayerName(name) {
      if (name.length > 25) return name.slice(0, 25) + "...";
      else return name;
    },

    previewPlayer(player) {
      this.playerPreview = player;
      this.playerPreviewDialog = true;
    },
  },
  computed: {
    ...mapState("appStore", ["newTeamData", "allFantasies"]),

    filterPlayersBySearch() {
      let sortedPlayers = this.team.players.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
      return sortedPlayers.filter((player) =>
        player.name.toLowerCase().includes(this.searchFilter.toLowerCase())
      );
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    if (!this.newTeamData.name || this.allFantasies.length <= 0)
      this.$router.push(`/fantasy/${this.$route.params.fantasyId}`);

    this.fantasyData = this.allFantasies.find(
      (fantasy) => fantasy.id == this.$route.params.fantasyId
    );
    if (
      this.fantasyData.jsonTeams.includes("firebase") ||
      this.fantasyData.jsonTeams.includes("digitalocean")
    ) {
      let params = {
        url: this.fantasyData.jsonTeams,
        teamId: this.$route.params.teamId,
      };
      const getRoster = firebase.functions().httpsCallable("getRosterJson");
      let roster = await getRoster(params);
      this.team = roster.data;
    } else {
      try {
        let jsonTeams = require(`@/assets/${this.fantasyData.jsonTeams}`).teams;
        this.team = jsonTeams[this.$route.params.teamId];
      } catch (err) {}
    }
  },
  components: {
    BackToHome,
  },
};
</script>
