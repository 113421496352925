import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

import router from "@/router";
import Notify from 'quasar/src/plugins/Notify.js';;

const state = {
  loadingStatus: false,
  userData: null,
  currentUserTeam: null,
  allTeams: [],
  teamCreationStep: 0,
  newTeamData: {
    name: "",
    owner: "",
    score: 0,
    weekScore: 0,
    roster: [],
  },
  // ADAPTATION
  allFantasies: [],
  externalTeams: null,
};
const mutations = {
  setLoadingStatus(state, payload) {
    state.loadingStatus = payload;
  },
  setUserData(state, payload) {
    state.userData = payload;
  },
  setEmptyNewTeamData(state) {
    // state.teamCreationStep = 0;
    state.newTeamData.roster.forEach((player) => {
      player.role = null;
      player.isCaptain = false;
    });
    state.newTeamData = {
      name: "",
      owner: "",
      score: 0,
      roster: [],
    };
  },
  setNewTeamName(state, payload) {
    state.newTeamData.name = payload;
    state.newTeamData.owner = firebase.auth().currentUser.uid;
    state.newTeamData.leagueId = router.currentRoute.params.fantasyId;
    state.newTeamData.ownerName = `${state.userData.name} ${state.userData.lastName}`;
  },
  setNewTeamCreationStep(state, payload) {
    state.teamCreationStep = payload;
  },
  setNewTeamRoster(state, payload) {
    state.newTeamData.roster = payload;
  },
  setCurrentUserTeam(state, payload) {
    if (!payload) {
      state.currentUserTeam = payload;
      return;
    }
    if (payload.docs.length > 0) {
      let teams = [];
      let teamData = {};
      payload.forEach((team) => {
        teamData = team.data();
        teamData.id = team.id;
        teams.push(teamData);
      });
      state.currentUserTeam = teams;
    } else state.currentUserTeam = [];
  },
  setAllTeams(state, payload) {
    state.allTeams = payload;
  },
  pushNewTeamToAllTeams(state, payload) {
    state.allTeams.unshift(payload);
  },
  // ADAPTATION
  setAllFantasies(state, payload) {
    let f = [];
    payload.forEach((fantasy) => {
      let data = fantasy.data();
      data.id = fantasy.id;
      f.push(data);
    });
    state.allFantasies = f;
  },
  addPlayerToRoster(state, payload) {
    state.newTeamData.roster.push(payload);
  },
  removePlayerFromRoster(state, payload) {
    state.newTeamData.roster = state.newTeamData.roster.filter(
      (player) => player.id != payload.id
    );
  },
  setMainTeam(state, payload) {
    let main = {
      name: payload.name,
      id: payload.id,
      logo: payload.logo,
    };
    state.newTeamData.mainTeam = main;
  },
  setDeleteUserTeam(state, payload) {
    let filteredTeams = state.currentUserTeam.filter(
      (team) => team.id != payload
    );
    state.currentUserTeam = filteredTeams;
  },
  setIAmArray(state, payload) {
    state.userData.iAm = payload;
  },
  setExternalTeams(state, payload) {
    state.externalTeams = payload;
  },
};
const actions = {
  registerUser({ commit }, payload) {
    let sanitizedEmail = payload.email.replace(/\s/g, "");
    commit("setLoadingStatus", true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(sanitizedEmail, payload.password)
      .then((resp) => {
        firebase
          .firestore()
          .collection("users")
          .doc(resp.user.uid)
          .set({
            name: payload.name,
            lastName: payload.lastName,
            email: payload.email,
          })
          .then(() => {
            firebase
              .auth()
              .currentUser.sendEmailVerification()
              .then(() => {
                firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    router.push("/login");
                    commit("setUserData", null);
                    commit("setCurrentUserTeam", null);
                    Notify.create({
                      message:
                        "Te enviamos un correo de verificación. Tambien fijate en tu bandeja de correo no deseado.",
                      color: "green-6",
                      icon: "email",
                      position: "top",
                      badgeColor: "amber-6",
                      progress: true,
                      classes: "df-font w700i higher-toast",
                      timeout: 10000,
                    });
                    commit("setLoadingStatus", false);
                  })
                  .catch((err) => {
                    console.log(err);
                    commit("setLoadingStatus", false);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          });
      })
      .catch((e) => {
        commit("setLoadingStatus", false);
        Notify.create({
          message: e.message,
          color: "red-6",
          icon: "error",
          badgeColor: "amber-6",
          progress: true,
          classes: "df-font w700i higher-toast",
          timeout: 4000,
        });
        return e;
      });
  },
  loginUser({ commit }, payload) {
    commit("setLoadingStatus", true);
    commit("setNewTeamCreationStep", 0);
    firebase
      .auth()
      .signInWithEmailAndPassword(
        payload.email.replace(/\s/g, ""),
        payload.password
      )
      .then((resp) => {
        if (!resp.user.emailVerified) {
          firebase
            .auth()
            .signOut()
            .then(() => {
              Notify.create({
                message:
                  "Primero debes verificar tu correo electrónico. Tambien fijate en tu bandeja de correo no deseado.",
                color: "red-6",
                icon: "email",
                badgeColor: "amber-6",
                position: "top",
                progress: true,
                classes: "df-font w700i higher-toast",
                timeout: 10000,
              });
              commit("setLoadingStatus", false);
            })
            .catch((err) => {
              console.log(err);
              commit("setLoadingStatus", false);
            });
        } else {
          router.push("/");
          firebase
            .firestore()
            .collection("users")
            .doc(firebase.auth().currentUser.uid)
            .get()
            .then((resp) => {
              let data = resp.data();
              data.id = resp.id;
              commit("setUserData", data);
            });

          commit("setLoadingStatus", false);
        }
      })
      .catch((err) => {
        Notify.create({
          message: err.message,
          color: "red-6",
          icon: "error",
          position: "top",
          badgeColor: "amber-6",
          progress: true,
          classes: "df-font w700i higher-toast",
          timeout: 5000,
        });
        commit("setLoadingStatus", false);
      });
  },
  async getUserData({ commit }, payload) {
    commit("setLoadingStatus", true);
    const getUser = await firebase
      .firestore()
      .collection("users")
      .doc(payload)
      .get();
    try {
      const getTeam = await firebase
        .firestore()
        .collection("teams")
        .where("owner", "==", firebase.auth().currentUser.uid)
        .orderBy("createdAt", "desc")
        .get();
      if (!getTeam.empty) {
        commit("setCurrentUserTeam", getTeam);
      }
    } catch (err) {
      console.log(err);
    }
    let u = getUser.data();
    u.id = firebase.auth().currentUser.uid;
    commit("setUserData", u);
    commit("setLoadingStatus", false);
  },
  logoutUser({ commit }) {
    commit("setUserData", null);
    commit("setCurrentUserTeam", null);
    router.push("/");
    firebase
      .auth()
      .signOut()
      .then(() => {
        Notify.create({
          message: "Cerraste sesion satisfactoriamente",
          color: "green-6",
          icon: "check",
          badgeColor: "amber-6",
          progress: true,
          classes: "df-font w700i higher-toast",
          timeout: 1000,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },
  sendPasswordReset({ commit }, payload) {
    commit("setLoadingStatus", true);
    firebase
      .auth()
      .sendPasswordResetEmail(payload)
      .then((resp) => {
        commit("setLoadingStatus", false);
        firebase.auth().signOut();
        commit("setUserData", null);
        commit("setCurrentUserTeam", null);
        router.push("/login");
        Notify.create({
          message: "Solicitud enviada con exito.",
          color: "green-6",
          icon: "check",
          badgeColor: "amber-6",
          progress: true,
          classes: "df-font w700i higher-toast",
          timeout: 4000,
        });
      })
      .catch((e) => {
        Notify.create({
          message: e.message,
          color: "red-6",
          icon: "error",
          badgeColor: "amber-6",
          progress: true,
          classes: "df-font w700i higher-toast",
          timeout: 4000,
        });
        commit("setLoadingStatus", false);
      });
  },
  cancelNewTeamCreation({ commit }) {
    commit("setEmptyNewTeamData");
    router.push(`/fantasy/${router.currentRoute.params.fantasyId}`);
  },
  storeNewTeamName({ commit }, payload) {
    commit("setNewTeamName", payload);
    router.push(`/oficial-teams-list/${router.currentRoute.params.fantasyId}`);
    // commit("setNewTeamCreationStep", 1);
  },
  storeNewTeamRoster({ commit }, payload) {
    commit("setNewTeamCreationStep", 2);
    let roster = [];
    for (let team of payload) {
      for (let player of team.players) {
        if (player.selected) {
          roster.push({
            name: player.name,
            id: player.id,
            team: team.name,
            role: "",
            isRookie: player.isRookie,
          });
        }
      }
    }
    commit("setNewTeamRoster", roster);
  },
  storeNewTeamRosterRoles({ commit }, payload) {
    commit("setNewTeamRoster", payload);
    commit("setNewTeamCreationStep", 3);
  },
  finishNewTeamCreation({ commit }, payload) {
    commit("setLoadingStatus", true);
    let finalTeamData = state.newTeamData;
    finalTeamData.owner = firebase.auth().currentUser.uid;
    finalTeamData.ownerName = `${state.userData.name} ${state.userData.lastName}`;
    finalTeamData.selectedTeam = payload;
    const storeNewTeam = firebase.functions().httpsCallable("storeNewTeam");
    storeNewTeam(finalTeamData).then((resp) => {
      if (resp.data.type == "succ") {
        commit("setCurrentUserTeam", finalTeamData);
        if (state.allTeams.length)
          commit("pushNewTeamToAllTeams", finalTeamData);
        router.push("/?succ=true");
      } else {
        Notify.create({
          message: resp.data.msg,
          color: "red-6",
          icon: "error",
          badgeColor: "amber-6",
          progress: true,
          classes: "df-font w700i higher-toast",
          timeout: 4000,
        });
      }
      commit("setLoadingStatus", false);
    });
  },
  async getAllTeams({ commit }) {
    commit("setLoadingStatus", true);
    const teams = await firebase
      .firestore()
      .collection("teams")
      .where("leagueId", "==", router.currentRoute.params.fantasyId)
      .orderBy("score", "desc")
      .get();
    let t = {};
    let at = [];
    teams.docs.forEach((team) => {
      t = team.data();
      t.id = team.id;
      at.push(t);
    });
    commit("setAllTeams", at);
    commit("setLoadingStatus", false);
  },
  updateUserData() {
    const uid = firebase.auth().currentUser.uid;
    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update(state.userData);
  },
  // ADAPTATION
  async getAllFantasies({ commit }) {
    commit("setLoadingStatus", true);
    let resp = await firebase
      .firestore()
      .collection("fantasies")
      .get();
    commit("setAllFantasies", resp);
    commit("setLoadingStatus", false);
  },
  addPlayerToNewTeamRoster({ commit }, payload) {
    let player = payload.player;
    player.teamId = payload.team;
    player.teamName = payload.teamName;
    player.teamLogo = payload.logo;
    commit("addPlayerToRoster", player);
    Notify.create({
      message: "Jugador agregado con éxito.",
      color: "green-6",
      icon: "check",
      position: "top",
      badgeColor: "amber-6",
      progress: true,
      classes: "df-font w700i higher-toast",
      timeout: 2000,
    });
  },
  removePlayerToNewTeamRoster({ commit }, payload) {
    payload.isCaptain = false;
    payload.role = null;
    commit("removePlayerFromRoster", payload);
    Notify.create({
      message: "Jugador removido con éxito.",
      color: "red-6",
      icon: "check",
      position: "top",
      badgeColor: "amber-6",
      progress: true,
      classes: "df-font w700i higher-toast",
      timeout: 2000,
    });
  },
  selectMainTeam({ commit }, payload) {
    commit("setMainTeam", payload);
  },
  async storeNewTeam({ commit }) {
    commit("setLoadingStatus", true);
    let newTeam = state.newTeamData;
    newTeam.createdAt = Date.now();
    const createNewFantasyTeam = firebase
      .functions()
      .httpsCallable("createNewFantasyTeam");
    let resp = await createNewFantasyTeam(newTeam);
    router.push(
      `/fantasy-team-creation/${router.currentRoute.params.fantasyId}/${resp.data}`
    );
    commit("setLoadingStatus", false);
  },
  async deleteUserTeam({ commit }, payload) {
    commit("setDeleteUserTeam", payload);
    firebase
      .firestore()
      .collection("teams")
      .doc(payload)
      .delete();
    setTimeout(() => {
      router.back();
    }, 1000);
  },
  async addIAm({ commit }, payload) {
    commit("setLoadingStatus", true);
    let el;
    if (!state.userData.iAm) el = [payload];
    else {
      el = state.userData.iAm;
      el.push(payload);
    }
    await firebase
      .firestore()
      .collection("users")
      .doc(state.userData.id)
      .update({ iAm: el });
    commit("setIAmArray", el);
    router.push("/profile");
    commit("setLoadingStatus", false);
  },
  removeIAm({ commit }, payload) {
    if (confirm("¿Seguro deseas eliminar este jugador?")) {
      let newIAm = state.userData.iAm.filter((player) => player.id != payload);
      firebase
        .firestore()
        .collection("users")
        .doc(state.userData.id)
        .update({ iAm: newIAm });
      commit("setIAmArray", newIAm);
    }
  },
  showHideLoading({ commit }, payload) {
    commit("setLoadingStatus", payload);
  },
  storeTeams({ commit }, payload) {
    commit("setExternalTeams", payload);
  },
};
const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
