import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./quasar";

import LoadingDialog from "@/components/LoadingDialog";

import firebase from "firebase/app";
import "firebase/analytics";

import VueSmoothScroll from "vue2-smooth-scroll";

const firebaseConfig = {
  apiKey: "AIzaSyASSjJ52fq99QHpJIBXK_j3DcWRqe0qUJY",
  authDomain: "demfantasy.firebaseapp.com",
  projectId: "demfantasy",
  storageBucket: "demfantasy.appspot.com",
  messagingSenderId: "309573901534",
  appId: "1:309573901534:web:56b53dfb98504b76a5764f",
  measurementId: "G-2GQV2LVR76",
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.prototype.$analytics = firebase.analytics();

Vue.use(VueSmoothScroll, {
  offset: -100,
});

// UNCOMMENT FOR DEV PURPOSES
if (document.URL.includes("localhost")) {
  firebase.functions().useEmulator("localhost", 5001);
  firebase.firestore().useEmulator("localhost", 8081);
}

Vue.config.productionTip = false;

Vue.component("loading-dialog", LoadingDialog);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
