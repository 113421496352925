<template>
  <q-page class="q-pa-md bg-grey-9">
    <q-btn
      label="Volver"
      flat
      icon="arrow_back"
      no-caps
      class="w700i q-my-md"
      color="amber-7"
      rounded
      to="/"
    />
    <div class="text-h4 w700i q-py-md q-mb-lg text-white">
      Registro
    </div>
    <div class="row q-mb-md">
      <div class="col q-px-xs">
        <q-input
          rounded
          standout="bg-amber-7"
          label-color="white"
          label="Nombre"
          color="grey-6"
          dark
          v-model="registerData.name"
        />
      </div>
      <div class="col q-px-xs">
        <q-input
          rounded
          standout="bg-amber-7"
          label-color="white"
          label="Apellido"
          color="grey-6"
          dark
          v-model="registerData.lastName"
        />
      </div>
    </div>
    <div class="row q-px-xs q-mb-md">
      <div class="col">
        <q-input
          rounded
          standout="bg-amber-7"
          label-color="white"
          color="grey-6"
          dark
          label="Correo electronico"
          v-model="registerData.email"
          type="email"
        />
      </div>
    </div>
    <div class="row q-px-xs q-mb-md">
      <div class="col">
        <q-input
          rounded
          standout="bg-amber-7"
          label-color="white"
          color="grey-6"
          dark
          label="Contraseña"
          :type="isPwd ? 'password' : 'text'"
          v-model="registerData.password"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
          </template>
        </q-input>
      </div>
    </div>
    <div class="row q-px-xs q-mb-xl">
      <div class="col">
        <q-input
          rounded
          standout="bg-amber-7"
          label-color="white"
          color="grey-6"
          dark
          label="Repetir contraseña"
          :type="isRePwd ? 'password' : 'text'"
          v-model="registerData.rePassword"
        >
          <template v-slot:append>
            <q-icon
              :name="isRePwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isRePwd = !isRePwd"
            />
          </template>
        </q-input>
      </div>
    </div>
    <div class="row justify-end q-mb-xl">
      <q-btn
        label="Registrar"
        class="w700i q-px-lg"
        rounded
        outline
        no-caps
        color="amber-7"
        @click="register()"
      />
    </div>
    <div class="text-center text-caption text-grey-7 absolute-bottom q-py-lg">
      Desarrollado por
      <a
        class="text-grey-5"
        href="https://www.instagram.com/demplays/"
        target="_blank"
        >DemPlays</a
      >
      &amp;
      <a class="text-grey-5" href="https://www.blueballoon.io" target="_blank"
        >Blue Balloon Inc.</a
      >
    </div>
    <!-- DIALOG -->
    <q-dialog v-model="disclaimerDialog" persistent>
      <q-card class="df-font" style="border-radius: 20px;">
        <q-card-section>
          <div class="text-h5 w700i text-grey-10">
            Terminos y condiciones
          </div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="text-body2 q-mb-md text-center">
            Antes de registrarte debes aceptar los términos y condiciones de
            esta aplicación.
          </div>
          <div
            class="text-body2 q-mb-md text-center text-amber-7 text-bold"
            @click="termsDialog = true"
          >
            <u>Leer aquí</u>
          </div>
          <div class="row justify-center">
            <q-checkbox
              v-model="acceptDialog"
              label="Acepto términos y condiciones"
              color="black"
            />
          </div>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn
            label="Continuar"
            class="full-width q-mb-md w700i"
            color="amber-7"
            :disable="!acceptDialog"
            @click="disclaimerDialog = false"
            rounded
            unelevated
          />
          <q-btn
            label="Cancelar"
            class="full-width w700i"
            flat
            color="red-7"
            to="/"
            no-caps
            rounded
            unelevated
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog
      maximized
      v-model="termsDialog"
      transition-hide="slide-down"
      transition-show="slide-up"
    >
      <q-card class="bg-grey-9 text-white">
        <q-card-actions align="right">
          <q-btn
            flat
            round
            icon="close"
            color="amber-7"
            @click="termsDialog = false"
          />
        </q-card-actions>
        <q-card-section>
          <div class="text-h6 text-center df-font w700">
            Términos y condiciones
          </div>
        </q-card-section>
        <q-card-section>
          <TermsAndConditions />
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { mapActions } from "vuex";
import TermsAndConditions from "@/components/TermsAndConditions";

export default {
  data() {
    return {
      isPwd: true,
      isRePwd: true,
      disclaimerDialog: true,
      termsDialog: false,
      acceptDialog: false,
      registerData: {
        name: "",
        lastName: "",
        email: "",
        password: "",
        rePassword: "",
      },
    };
  },
  methods: {
    ...mapActions("appStore", ["registerUser"]),

    register() {
      if (
        !this.registerData.name ||
        !this.registerData.lastName ||
        !this.registerData.email ||
        !this.registerData.password ||
        !this.registerData.rePassword
      ) {
        this.$q.notify({
          message: "Debes llenar todos los campos",
          color: "red-6",
          icon: "error",
          badgeColor: "amber-6",
          position: "top",
          progress: true,
          classes: "df-font w700i higher-toast",
          timeout: 2000,
        });
        return;
      }
      if (this.registerData.password != this.registerData.rePassword) {
        this.$q.notify({
          message: "Las contraseñas no coinciden",
          color: "red-6",
          icon: "error",
          badgeColor: "amber-6",
          position: "top",
          progress: true,
          classes: "df-font w700i higher-toast",
          timeout: 2000,
        });
        return;
      }
      this.registerUser(this.registerData);
    },
  },
  components: {
    TermsAndConditions,
  },
};
</script>
