<template>
  <q-page class="q-pa-md bg-grey-9">
    <q-btn
      label="Volver"
      flat
      icon="arrow_back"
      no-caps
      class="w700i q-my-md"
      color="amber-7"
      rounded
      to="/login"
    />
    <div class="text-h4 w700i q-py-md q-mb-lg text-white">
      Olvide mi contraseña
    </div>
    <div class="row q-mb-md">
      <q-input
        rounded
        standout="bg-amber-7"
        label-color="white"
        color="grey-6"
        dark
        label="Correo electronico"
        class="full-width"
        v-model="email"
      />
    </div>
    <div class="row q-mb-xl">
      <q-btn
        label="Enviar"
        no-caps
        color="amber-7"
        rounded
        unelevated
        class="w700i full-width"
        @click="send()"
      />
    </div>
    <div class="text-body2 q-px-md text-white">
      Te enviaremos un correo con un enlace donde podras actualizar tu
      contraseña.<br />
      <br />
      <span class="text-amber-7"
        >Recuerda verificar tu bandeja de correo no deseado.</span
      >
    </div>
  </q-page>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    ...mapActions("appStore", ["sendPasswordReset"]),

    send() {
      if (!this.email) {
        this.$q.notify({
          message: "Debes ingresar tu correo electronico.",
          color: "red-6",
          icon: "error",
          badgeColor: "amber-6",
          progress: true,
          classes: "df-font w700i higher-toast",
          timeout: 2000,
        });
        return;
      }
      this.sendPasswordReset(this.email);
    },
  },
};
</script>
