<template>
  <q-page class="bg-grey-9 q-pa-md" v-if="$route.params.password == 'p455w0rd'">
    <q-select
      label="Seleccionar Fantasy"
      color="amber-7"
      class="q-mb-md"
      filled
      dark
      :options="fantasies"
      emit-value
      map-options
      v-model="selectedFantasy"
    />
    <q-input
      label="WIPE THEM ALL"
      filled
      color="amber-7"
      dark
      v-model="wipeInput"
    />
    <div class="q-pa-md absolute-bottom">
      <q-btn
        label="Wipe them ALL"
        color="amber-7"
        push
        class="full-width w700"
        :disable="wipeInput != 'WIPE THEM ALL' || !selectedFantasy"
        @click="wipeAllTeamsFromFantasy()"
      />
    </div>
  </q-page>
</template>

<script>
import { mapActions } from "vuex";
import firebase from "firebase/app";
import "firebase/functions";

export default {
  data() {
    return {
      fantasies: [],
      selectedFantasy: "",
      wipeInput: "",
    };
  },
  methods: {
    ...mapActions("appStore", ["showHideLoading"]),

    async wipeAllTeamsFromFantasy() {
      console.log("WIPING TEAMS");
      this.showHideLoading(true);
      const wipeTeams = firebase.functions().httpsCallable("wipeTeams");
      let resp = await wipeTeams(this.selectedFantasy);
      console.log(resp);
      if (resp.data == 1) {
        alert("Equipos eliminados con exito.");
        this.wipeInput = "";
        this.selectedFantasy = "";
      }
      this.showHideLoading(false);
    },
  },
  async mounted() {
    this.showHideLoading(true);
    let resp = await firebase
      .firestore()
      .collection("fantasies")
      .where("isActive", "==", true)
      .get();
    resp.forEach((fantasy) => {
      let f = {};
      f.value = fantasy.id;
      f.label = fantasy.data().name;
      this.fantasies.push(f);
    });
    this.showHideLoading(false);
  },
};
</script>
