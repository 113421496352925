<template>
  <div>
    <q-separator class="q-my-md" dark />
    <div class="row">
      <div class="col">
        <q-btn
          label="Cancelar proceso"
          color="red"
          flat
          class="full-width"
          @click="confirmReset = true"
        />
      </div>
    </div>
    <q-dialog v-model="confirmReset">
      <q-card class="df-font bg-red-7" style="border-radius: 20px;">
        <q-card-section>
          <div class="text-h6 w700i text-white">Seguro deseas cancelar?</div>
        </q-card-section>
        <q-separator dark />
        <q-card-section>
          <div class="text-body2 text-white">
            Si cancelas el proceso de creación de equipo tendras que empezar
            todo nuevamente.
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            label="Deseo continuar"
            rounded
            flat
            no-caps
            class="w700i q-px-md"
            color="white"
            @click="confirmReset = false"
          />
          <q-btn
            label="Si deseo cancelar"
            rounded
            unelevated
            no-caps
            class="w700i text-red-7 q-px-md"
            color="white"
            @click="cancelNewTeamCreation()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      confirmReset: false,
    };
  },
  methods: {
    ...mapActions("appStore", ["cancelNewTeamCreation"]),
  },
};
</script>
