<template>
  <q-page class="q-pa-md bg-grey-9" v-if="userData">
    <q-btn
      label="Volver"
      flat
      icon="arrow_back"
      no-caps
      class="w700i q-my-md"
      color="amber-7"
      rounded
      to="/"
    />
    <div class="text-h4 w700i q-py-md q-mb-lg text-white">Mi perfil</div>
    <q-card class="q-mb-lg" style="border-radius: 20px;">
      <q-card-section>
        <div class="row q-mb-md">
          <div class="col q-px-xs">
            <q-input
              :disable="!isEdit"
              label="Nombre"
              v-model="userData.name"
              rounded
              label-color="grey-10"
              standout="bg-amber-7"
              color="grey-6"
            />
          </div>
          <div class="col q-px-xs">
            <q-input
              :disable="!isEdit"
              label="Apellido"
              v-model="userData.lastName"
              rounded
              label-color="grey-10"
              standout="bg-amber-7"
              color="grey-6"
            />
          </div>
        </div>
        <div class="row">
          <div class="col q-px-xs">
            <q-input
              disable
              label="Correo electronico"
              v-model="userData.email"
              rounded
              label-color="grey-10"
              standout="bg-amber-7"
              color="grey-6"
            />
          </div>
        </div>
      </q-card-section>
      <!-- <q-card-actions align="right">
        <div class="q-px-md">
          <q-btn
            label="Editar"
            color="amber-7"
            class="w700i q-px-md"
            rounded
            flat
            no-caps
            @click="isEdit = !isEdit"
            v-if="!isEdit"
          />
          <q-btn
            label="Guardar"
            color="amber-7"
            class="w700i q-px-md"
            rounded
            flat
            no-caps
            @click="updateUser()"
            v-else
          />
        </div>
      </q-card-actions> -->
    </q-card>
    <div class="row items-center q-mb-md">
      <div class="text-h5 w700i text-white">Mis equipos</div>
      <q-space />
      <q-btn
        :label="isDeleteActive ? 'Cancelar' : 'Gestionar equipos'"
        rounded
        outline
        color="amber-7"
        class="w700"
        no-caps
        size="sm"
        @click="
          isDeleteActive = !isDeleteActive;
          seamless = false;
          deleteTeamId = '';
          confirmDelete = false;
        "
        v-if="allFantasies.length > 0"
      />
    </div>
    <q-card class="q-mb-lg" style="border-radius: 20px">
      <q-list separator v-if="currentUserTeam">
        <q-item v-for="team in currentUserTeam" :key="team.id">
          <q-item-section
            avatar
            @click="$router.push(`/my-team/${team.leagueId}`)"
          >
            <q-avatar>
              <q-img
                :src="team.mainTeam.logo.attributes.url"
                v-if="team.mainTeam.logo.attributes"
              />
              <q-img :src="team.mainTeam.logo" v-else />
            </q-avatar>
          </q-item-section>
          <q-item-section @click="$router.push(`/my-team/${team.leagueId}`)">
            {{ team.name }}
          </q-item-section>
          <q-item-section avatar class="text-amber-7 w700">
            {{ team.score }} pts.
          </q-item-section>
          <q-item-section avatar v-if="isDeleteActive">
            <q-btn
              icon="remove"
              round
              unelevated
              color="red-7"
              size="xs"
              @click="
                deleteTeamId = team.id;
                seamless = true;
              "
              :disable="!canDelete(team.leagueId)"
            />
          </q-item-section>
        </q-item>
      </q-list>
      <div class="text-subtitle2 w700i text-center text-grey-5 q-py-xl" v-else>
        No tienes equipos registrados.
      </div>
    </q-card>
    <div class="row items-center q-mb-md">
      <div class="text-h5 w700i text-white">Soy jugador</div>
      <q-space />
      <q-btn
        icon="add"
        color="amber-7"
        size="sm"
        round
        to="/user-plays-in-league"
      />
    </div>

    <q-list
      class="bg-white q-mb-xl"
      style="border-radius: 20px;"
      v-if="userData.iAm && userData.iAm.length > 0"
      separator
    >
      <q-item v-for="player in userData.iAm" :key="player.id">
        <q-item-section avatar v-if="player.profile">
          <q-avatar>
            <img :src="player.profile" />
          </q-avatar>
        </q-item-section>
        <q-item-section>
          <div class="text-body2 w700i">{{ player.name }}</div>
          <div class="text-caption">{{ player.leagueName }}</div>
        </q-item-section>
        <q-item-section avatar>
          <q-avatar>
            <q-btn
              icon="delete"
              round
              color="red-7"
              size="sm"
              flat
              @click="removeIAm(player.id)"
            />
          </q-avatar>
        </q-item-section>
      </q-item>
    </q-list>
    <q-card style="border-radius: 20px;" class="q-mb-xl" v-else>
      <q-card-section>
        <div class="text-subtitle2 text-grey-7 text-center w700i">
          Aún no has asignado jugadores.
        </div>
      </q-card-section>
    </q-card>
    <q-btn
      label="Solicitar cambio de contraseña"
      color="amber-7"
      class="full-width q-mb-lg w700i"
      unelevated
      rounded
      no-caps
      @click="sendPasswordReset(userData.email)"
    />
    <q-btn
      label="Cerrar sesion"
      color="red-7"
      class="full-width q-mb-lg w700i"
      flat
      rounded
      @click="logoutUser()"
    />
    <q-dialog v-model="seamless" seamless position="bottom">
      <q-card style="width: 350px" class="bg-red-7 text-white df-font">
        <q-card-section class="row q-pb-none">
          <div class="text-h6 w700i">Eliminar equipo</div>
          <q-space />
          <q-btn
            icon="close"
            round
            size="sm"
            flat
            color="white"
            @click="
              seamless = false;
              confirmDelete = false;
              isDeleteActive = false;
              deleteTeamId = '';
            "
          />
        </q-card-section>
        <q-card-section class="q-pb-none">
          <div class="text-h5 text-center w700i">{{ returnTeamName }}</div>
        </q-card-section>
        <q-card-section class="q-pb-none">
          <div class="text-body2">
            Si aún deseas participar del fantasy, recuerda que debes volver a
            crear tu equipo antes de<span class="w700">
              {{ returnFinalizationDateAndTime }}</span
            >
          </div>
        </q-card-section>
        <q-card-section class="row justify-center q-pb-none">
          <q-checkbox
            v-model="confirmDelete"
            color="red-7"
            dark
            label="Deseo eliminar mi equipo"
          />
        </q-card-section>
        <q-card-section>
          <div class="row justify-center q-mb-md">
            <q-btn
              label="Eliminar"
              rounded
              push
              color="white"
              class="text-red-7 w700i q-px-xl"
              no-caps
              :disable="!confirmDelete"
              @click="deleteTeam()"
            />
          </div>
          <div class="row justify-center">
            <q-btn
              label="Cancelar"
              rounded
              flat
              color="white"
              class="w700i q-px-xl"
              no-caps
              @click="
                seamless = false;
                confirmDelete = false;
                isDeleteActive = false;
                deleteTeamId = '';
              "
            />
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      isEdit: false,
      isDeleteActive: false,
      seamless: false,
      confirmDelete: false,
      deleteTeamId: "",
    };
  },
  methods: {
    ...mapActions("appStore", [
      "logoutUser",
      "updateUserData",
      "sendPasswordReset",
      "deleteUserTeam",
      "removeIAm",
    ]),

    deleteTeam() {
      this.deleteUserTeam(this.deleteTeamId);
      this.seamless = false;
    },
    canDelete(leagueId) {
      let f = this.allFantasies.find((fantasy) => fantasy.id == leagueId);
      let closingDate = new Date(
        `${f.registrationEndDate} ${f.registrationEndTime}`
      );
      let now = new Date();
      if (now > closingDate) return false;
      else return true;
    },
    updateUser() {
      if (!this.userData.name || !this.userData.lastName) {
        alert("fix");
        return;
      }
      this.updateUserData();
      this.isEdit = false;
    },
  },
  computed: {
    ...mapState("appStore", ["userData", "currentUserTeam", "allFantasies"]),

    returnFinalizationDateAndTime() {
      try {
        let team = this.currentUserTeam.find(
          (team) => team.id == this.deleteTeamId
        );
        let league = this.allFantasies.find(
          (fantasy) => fantasy.id == team.leagueId
        );
        let finalization = `${league.registrationEndDate} - ${league.registrationEndTime}`;
        return finalization;
      } catch (e) {
        return "";
      }
    },
    returnTeamName() {
      try {
        let team = this.currentUserTeam.find(
          (team) => team.id == this.deleteTeamId
        );
        return team.name;
      } catch (e) {
        return "";
      }
    },
  },
};
</script>
