import { render, staticRenderFns } from "./Profile.vue?vue&type=template&id=74a7cac0&"
import script from "./Profile.vue?vue&type=script&lang=js&"
export * from "./Profile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QBtn,QCard,QCardSection,QInput,QCardActions,QSpace,QList,QItem,QItemSection,QAvatar,QImg,QDialog,QCheckbox});
