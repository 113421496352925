<template>
  <q-page class="q-pa-md bg-grey-9">
    <div class="row">
      <q-btn
        label="Atrás"
        icon="arrow_back"
        flat
        rounded
        color="amber-7"
        class="df-font w700 q-mb-lg"
        size="sm"
        @click="$router.push(`/oficial-teams-list/${$route.params.fantasyId}`)"
      />
      <q-space />
      <q-btn
        label="Finalizar"
        unelevated
        icon-right="done"
        rounded
        color="amber-7"
        class="df-font w700 q-mb-lg q-px-md"
        size="sm"
        @click="storeNewTeam()"
        :disable="!selectedTeam"
      />
    </div>

    <div class="text-h5 text-amber-7 text-center df-font w700i q-mb-lg">
      Selecciona tu equipo de preferencia
    </div>
    <q-input
      label="Buscar equipo"
      v-model="searchFilter"
      rounded
      standout="bg-amber-7"
      label-color="white"
      color="grey-6"
      dark
      class="q-mb-lg"
    />
    <q-card style="border-radius: 20px;" class="q-mb-md">
      <q-list separator>
        <q-item
          clickable
          v-ripple
          v-for="team in filterTeamsByName"
          :key="team.id"
          @click="selectTeam(team)"
        >
          <q-item-section avatar>
            <q-avatar>
              <img
                v-if="team.logo.attributes"
                :src="team.logo.attributes.url"
              />
              <img v-else :src="team.logo" />
            </q-avatar>
          </q-item-section>
          <q-item-section>{{ team.name }}</q-item-section>
          <q-item-section avatar>
            <q-icon
              name="star"
              color="amber-7"
              v-if="selectedTeam && team.id == selectedTeam.id"
            />
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
    <q-btn
      label="Finalizar"
      color="amber-7"
      rounded
      class="df-font w700 full-width"
      push
      :disable="!selectedTeam"
      @click="storeNewTeam()"
    />
    <BackToHome />
  </q-page>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BackToHome from "@/components/BackToHome";

export default {
  data() {
    return {
      fantasyData: null,
      teams: [],
      selectedTeam: null,
      searchFilter: "",
    };
  },
  methods: {
    ...mapActions("appStore", ["selectMainTeam", "storeNewTeam"]),

    selectTeam(team) {
      this.selectedTeam = team;
      this.selectMainTeam(team);
    },
  },
  computed: {
    ...mapState("appStore", ["allFantasies", "newTeamData", "externalTeams"]),

    filterTeamsByName() {
      return this.teams.filter((team) =>
        team.name.toLowerCase().includes(this.searchFilter.toLowerCase())
      );
    },
  },
  components: {
    BackToHome,
  },
  mounted() {
    window.scrollTo(0, 0);
    if (
      this.allFantasies.length <= 0 ||
      !this.newTeamData.name ||
      this.newTeamData.roster.length <= 0
    )
      this.$router.push(`/fantasy/${this.$route.params.fantasyId}`);

    this.fantasyData = this.allFantasies.find(
      (fantasy) => fantasy.id == this.$route.params.fantasyId
    );
    try {
      let jsonTeams = require(`@/assets/${this.fantasyData.jsonTeams}`).teams;
      let teams = [];
      for (const i in jsonTeams) {
        jsonTeams[i].id = i;
        teams.push(jsonTeams[i]);
      }
      this.teams = teams.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    } catch (err) {
      this.teams = this.externalTeams;
    }
    if (this.newTeamData.mainTeam)
      this.selectedTeam = this.newTeamData.mainTeam;
  },
};
</script>
