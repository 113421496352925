<template>
  <q-page
    class="img-bg"
    :style="`background-image: url(${fantasyData.bgImages[returnRandomImg]})`"
    v-if="fantasyData"
  >
    <div style="height: 60vh" class="q-px-lg">
      <!-- <div class="row justify-center1 q-mb-lg">
        <img src="@/assets/logo.png" width="60" />
      </div> -->
      <div class="row items-center">
        <q-btn
          label="Volver al inicio"
          icon="arrow_back"
          color="amber-7"
          class="q-my-md"
          rounded
          size="sm"
          unelevated
          flat
          to="/"
        />
        <q-space />
        <q-btn
          label="Instalar"
          icon="download"
          unelevated
          rounded
          color="amber-7"
          class="w700i"
          size="xs"
          to="/install-select"
          v-if="!checkIfInstalled"
        />
      </div>

      <div class="text-h4 w700i text-amber-7">
        ¡Bienvenido!
      </div>
      <div class="text-h5 w700i text-white" v-if="userData">
        {{ userData.name }} {{ userData.lastName[0] }}.
      </div>
      <div class="row items-center q-mt-lg">
        <div class="col-6">
          <img :src="fantasyData.homeLogo" width="75%" />
        </div>

        <div class="col-6" v-if="fantasyData.sponsor">
          <div class="subtitle2 w700i text-amber-7 q-mb-sm">
            Patrocinado por:
          </div>
          <img :src="fantasyData.sponsor.logo" width="75%" />
        </div>
      </div>
    </div>
    <div class="absolute-bottom justify-center">
      <div class="row q-py-sm q-px-md">
        <div class="text-caption text-white w700i">
          Desarrollado por
          <a
            class="text-amber-7"
            target="_blank"
            href="https://www.instagram.com/demplays"
            >DemPlays</a
          >
          &amp;
          <a
            class="text-amber-7"
            target="_blank"
            href="https://www.blueballoon.io"
            >Blue Balloon Inc.</a
          >
        </div>
        <q-space />
        <div class="text-caption text-grey-4 w700i">
          v1.5.20
        </div>
      </div>
      <!-- <div class="text-caption text-white w700i q-pa-md">
        Desarrollado por
        <a
          class="text-amber-7"
          target="_blank"
          href="https://www.instagram.com/demplays"
          >DemPlays</a
        >
        &amp;
        <a
          class="text-amber-7"
          target="_blank"
          href="https://www.blueballoon.io"
          >Blue Balloon Inc.</a
        >
        <span class="q-pl-md">
          v1.5.0
        </span>
      </div> -->
      <div class="home-menu">
        <div class="row">
          <div
            class="col q-pa-sm"
            @click="$router.push(`/ranking/${$route.params.fantasyId}`)"
          >
            <div class="home-menu-item bg-grey-1 q-pa-md">
              <q-icon name="fas fa-trophy" size="md" class="q-mb-sm" /><br />
              <span>Ver ranking</span>
            </div>
          </div>
          <div
            class="col q-pa-sm"
            v-if="userData && hasTeamInFantasy"
            @click="$router.push(`/my-team/${$route.params.fantasyId}`)"
          >
            <div class="home-menu-item bg-grey-1 q-pa-md">
              <q-icon name="fas fa-users" size="md" class="q-mb-sm" /><br />
              <span>Mi equipo</span>
            </div>
          </div>
          <div
            class="col q-pa-sm"
            v-if="userData && !hasTeamInFantasy"
            @click="
              registrationEnded
                ? ''
                : $router.push(`/team-name-select/${$route.params.fantasyId}`)
            "
          >
            <div
              :class="
                `home-menu-item q-pa-md ${
                  registrationEnded ? 'bg-grey-5' : 'bg-grey-2'
                }`
              "
            >
              <q-icon
                name="fas fa-address-book"
                size="md"
                class="q-mb-sm"
              /><br />
              <span>{{
                registrationEnded ? "Registro finalizado" : "Crea tu equipo"
              }}</span>
            </div>
          </div>
          <div
            class="col q-pa-sm"
            v-if="!userData"
            @click="$router.push('/register')"
          >
            <div class="home-menu-item bg-grey-1 q-pa-md">
              <q-icon name="fas fa-bookmark" size="md" class="q-mb-sm" /><br />
              <span>Regístrate</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col q-pa-sm"
            @click="$router.push('/login')"
            v-if="!userData"
          >
            <div class="home-menu-item bg-grey-1 q-pa-md">
              <q-icon
                name="fas fa-sign-in-alt"
                size="md"
                class="q-mb-sm"
              /><br />
              <span>Inicia sesion</span>
            </div>
          </div>
          <div class="col q-pa-sm" @click="$router.push('/profile')" v-else>
            <div class="home-menu-item bg-grey-1 q-pa-md">
              <q-icon name="fas fa-user" size="lg" class="q-mb-sm" /><br />
              <span>Mi perfil</span>
            </div>
          </div>
          <div class="col q-pa-sm">
            <div
              class="home-menu-item bg-grey-1 q-pa-md"
              @click="rulesDialog = true"
            >
              <q-icon
                name="fas fa-book-reader"
                size="md"
                class="q-mb-sm"
              /><br />
              <span>Como funciona</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <q-dialog v-model="rulesDialog">
      <q-card class="df-font grey-3" style="border-radius: 20px;">
        <q-card-section>
          <div class="text-h5 w700i text-amber-7">Como funciona</div>
        </q-card-section>
        <q-separator />
        <WelcomeDialogSlide1 v-if="rulesStep == 1" />
        <WelcomeDialogSlide2 v-if="rulesStep == 2" />
        <WelcomeDialogSlide3 v-if="rulesStep == 3" />
        <WelcomeDialogSlide4 v-if="rulesStep == 4" />
        <q-card-actions align="right">
          <q-btn
            label="Atrás"
            flat
            color="amber-7"
            class="w700i q-px-md"
            rounded
            no-caps
            @click="rulesStep--"
            v-if="rulesStep > 1"
          />
          <q-btn
            :label="rulesStep >= 4 ? 'Terminar' : 'Siguiente'"
            color="amber-7"
            rounded
            class="w700i q-px-md"
            no-caps
            @click="rulesStepControl()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { mapState, mapActions } from "vuex";
import WelcomeDialogSlide1 from "@/components/WelcomeDialogSlide1";
import WelcomeDialogSlide2 from "@/components/WelcomeDialogSlide2";
import WelcomeDialogSlide3 from "@/components/WelcomeDialogSlide3";
import WelcomeDialogSlide4 from "@/components/WelcomeDialogSlide4";

export default {
  data() {
    return {
      rulesDialog: false,
      rulesStep: 1,
      fantasyData: null,
    };
  },
  methods: {
    ...mapActions("appStore", ["getAllFantasies", "getUserData"]),
    rulesStepControl() {
      if (this.rulesStep >= 4) {
        this.rulesDialog = false;
        this.rulesStep = 1;
      } else this.rulesStep++;
    },
  },
  async mounted() {
    if (this.allFantasies.length == 0) await this.getAllFantasies();
    this.fantasyData = this.allFantasies.find(
      (fantasy) => fantasy.id == this.$route.params.fantasyId
    );
    try {
      // if (this.$route.params.reload) this.getUserData(this.userData.id);
      this.getUserData(this.userData.id);
    } catch (err) {}
    // ANALYTICS
    let eventId = `open-fantasy-${this.fantasyData.name
      .replaceAll(" ", "-")
      .toLowerCase()}`;
    this.$analytics.logEvent(eventId);
  },
  computed: {
    ...mapState("appStore", ["userData", "currentUserTeam", "allFantasies"]),

    returnRandomImg() {
      let min = Math.ceil(0);
      let max = Math.floor(this.fantasyData.bgImages.length - 1);
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    registrationEnded() {
      let now = new Date();
      let endDate = new Date(
        `${this.fantasyData.registrationEndDate} ${this.fantasyData.registrationEndTime}`
      );
      return now > endDate;
    },

    checkIfInstalled() {
      return window.matchMedia("(display-mode: standalone)").matches;
    },
    hasTeamInFantasy() {
      let isInFantasy = false;
      if (this.currentUserTeam) {
        this.currentUserTeam.forEach((team) => {
          if (team.leagueId == this.$route.params.fantasyId) isInFantasy = true;
        });
      }

      return isInFantasy;
    },
  },
  components: {
    WelcomeDialogSlide1,
    WelcomeDialogSlide2,
    WelcomeDialogSlide3,
    WelcomeDialogSlide4,
  },
};
</script>
