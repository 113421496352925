<template>
  <q-page class="q-pa-md bg-grey-9" v-if="thisTeam && fantasyData">
    <q-btn
      label="Volver"
      flat
      icon="arrow_back"
      no-caps
      class="w700i q-my-md"
      color="amber-7"
      rounded
      :to="`/ranking/${$route.params.fantasyId}`"
    />
    <div class="row justify-center q-mt-md q-mb-md">
      <q-avatar class="bg-white" size="8em">
        <q-img
          :src="thisTeam.mainTeam.logo.attributes.url"
          v-if="thisTeam.mainTeam.logo.attributes"
        />
        <q-img :src="thisTeam.mainTeam.logo" v-else />
      </q-avatar>
    </div>
    <div class="text-h4 text-center q-mb-lg w700i text-amber-7">
      <span class="text-white text-h5 w700i">Puntos:</span>
      {{ thisTeam.score }}
    </div>
    <div class="text-h4 w700i text-center q-mb-lg text-white">
      {{ thisTeam.name }}<br /><span></span>
    </div>
    <div class="row">
      <div class="col">
        <div
          :class="
            `text-h6 q-mb-lg text-amber-7 w700i ${
              $route.params.position ? '' : 'text-center'
            }`
          "
        >
          <span class="w700i text-white">Entrenador:</span><br />{{
            thisTeam.ownerName
          }}
        </div>
      </div>
      <div class="col" v-if="$route.params.position">
        <div class="text-h6 text-amber-7 w700i">
          <span class="w700i text-white">Posicion en ranking:</span><br />#{{
            $route.params.position
          }}
        </div>
      </div>
    </div>
    <div class="row q-mb-md items-center">
      <div class="text-h5 w700i text-amber">Roster</div>
      <q-space />
      <q-btn
        label="compartir"
        color="amber-7"
        rounded
        unelevated
        class="w700i q-px-sm"
        icon="share"
        size="sm"
        @click="shareTeam()"
      />
    </div>

    <q-list bordered separator class="bg-white" style="border-radius: 20px;">
      <q-item
        v-for="player in thisTeam.roster"
        :key="player.id"
        clickable
        v-ripple
        @click="selectPlayer(player)"
      >
        <q-item-section avatar v-if="player.profile">
          <q-avatar>
            <q-img :src="player.profile" />
          </q-avatar>
        </q-item-section>
        <q-item-section>
          <div>
            <span
              class="w700i text-amber-7"
              v-if="player.isCaptain && fantasyData.rules.captainSelect"
              >(C)</span
            >
            <span class="w700i">
              {{ player.name }}
            </span>
            <q-icon
              name="male"
              size="1.2em"
              class="q-mr-xs"
              color="blue-6"
              v-if="player.gender == 'm' && fantasyData.rules.isGenderMix"
            />
            <q-icon
              name="female"
              size="1.2em"
              class="q-mr-xs"
              color="pink-6"
              v-if="player.gender == 'f' && fantasyData.rules.isGenderMix"
            />
            <span
              class="text-caption w700i text-amber-7"
              v-if="player.isRookie && fantasyData.rules.rookieModifier"
              >Rookie</span
            >
          </div>
          <div class="w700i text-caption text-grey-9">
            ({{ player.teamName }})
          </div>
        </q-item-section>
        <q-item-section side>
          <q-item-label caption>{{
            returnRoleLabel(player.role).label.toUpperCase()
          }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
    <q-dialog v-model="statsDialog">
      <q-card
        class="df-font"
        v-if="selectedPlayer"
        style="width: 700px; max-width: 80vw; border-radius: 20px;"
        flat
      >
        <q-card-section class="q-pb-none">
          <div class="row justify-center">
            <q-img
              :src="selectedPlayer.profile"
              style="border-radius: 20px; width: 150px;"
            />
          </div>
        </q-card-section>
        <q-card-section class="q-pb-none">
          <div class="text-h5 text-center w700i text-amber-7">
            {{ selectedPlayer.name }}
          </div>
          <div class="text-h6 text-center w700i">
            <span class="text-amber-7" v-if="selectedPlayer.isCaptain"
              >(C)</span
            >
            {{ returnRoleLabel(selectedPlayer.role).label }}
          </div>
        </q-card-section>
        <template v-if="!loading">
          <q-card-section>
            <div class="q-mb-md" v-for="(stat, index) in stats" :key="index">
              <div class="row">
                <div class="text-subtitle2 w700">
                  Semana <span class="w700i text-amber-7">{{ index + 1 }}</span
                  >:
                </div>
                <q-space />
                <div class="text-subtitle2 w700">
                  Puntos:
                  <q-badge
                    color="amber-7"
                    :label="calculatePoints(stat)"
                    style="font-size: 15px;"
                    class="q-py-xs"
                  />
                </div>
              </div>
              <q-separator class="q-my-xs" />
              <div class="row">
                <div
                  class="col text-center w700i"
                  style="text-transform: capitalize;"
                  v-for="(s, key, index) in stat"
                  :key="index"
                  v-show="index > 0"
                >
                  <div class="text-amber-7">{{ key }}</div>
                  {{ s }}
                </div>
              </div>
            </div>
          </q-card-section>
        </template>

        <q-card-section v-else>
          <div class="row justify-center">
            <q-spinner-dots size="2rem" color="amber-7" />
          </div>
        </q-card-section>
        <q-separator />
        <q-card-actions>
          <q-btn
            label="Cerrar"
            flat
            rounded
            @click="statsDialog = false"
            class="full-width w700"
            color="red-7"
            no-caps
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { mapActions, mapState } from "vuex";

import firebase from "firebase/app";
import "firebase/firebase-auth";
import "firebase/functions";

export default {
  data() {
    return {
      thisTeam: null,
      fantasyData: null,
      selectedPlayer: null,
      statsDialog: false,
      stats: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions("appStore", ["showHideLoading"]),

    selectPlayer(player) {
      if (!this.fantasyData.statsJson) return;
      this.selectedPlayer = player;
      this.statsDialog = true;
    },

    calculatePoints(stats) {
      let points = 0;
      this.fantasyData.calculateStats.forEach((stat) => {
        let p = stat.points * parseInt(stats[stat.stat]);
        if (this.selectedPlayer.role == stat.type) p = p * 2;
        if (
          this.fantasyData.rules.captainSelect &&
          this.selectedPlayer.isCaptain
        )
          p = p * 2;
        if (
          this.fantasyData.rules.rookieModifier &&
          this.selectedPlayer.isRookie
        )
          p = p * 2;
        points += p;
      });
      return points;
    },

    returnRoleLabel(role) {
      return this.fantasyData.roles.find((r) => r.value == role);
    },

    shareTeam() {
      let url;
      if (this.$route.params.position) url = window.location.href.slice(0, -2);
      else url = window.location.href;
      const shareData = {
        title: this.thisTeam.name,
        text: "Checkea este equipo de DEM Fantasy",
        url: url,
      };
      navigator.share(shareData);
    },
  },
  computed: {
    ...mapState("appStore", ["allTeams", "allFantasies"]),
  },
  watch: {
    statsDialog: async function() {
      if (this.statsDialog) {
        this.loading = true;
        const getStats = firebase.functions().httpsCallable("queryStatsJson");
        let resp = await getStats({
          urls: this.fantasyData.statsJson,
          player: this.selectedPlayer.id,
        });
        this.stats = resp.data;
        this.loading = false;
      }
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.showHideLoading(true);
    if (this.allTeams.length > 0) {
      this.thisTeam = this.allTeams.find(
        (team) => team.id == this.$route.params.teamId
      );
    } else {
      let resp = await firebase
        .firestore()
        .collection("teams")
        .doc(this.$route.params.teamId)
        .get();
      this.thisTeam = resp.data();
    }
    if (this.allFantasies.length > 0) {
      this.fantasyData = this.allFantasies.find(
        (fantasy) => fantasy.id == this.$route.params.fantasyId
      );
    } else {
      let resp = await firebase
        .firestore()
        .collection("fantasies")
        .doc(this.$route.params.fantasyId)
        .get();
      this.fantasyData = resp.data();
    }
    this.showHideLoading(false);
  },
};
</script>
