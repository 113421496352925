<template>
  <q-page class="bg-grey-9 q-pa-md" v-if="fantasyData">
    <div class="row">
      <q-btn
        label="Atrás"
        icon="arrow_back"
        rounded
        color="amber-7"
        flat
        class="df-font w700 q-mb-lg"
        size="sm"
        @click="$router.push(`/team-name-select/${$route.params.fantasyId}`)"
        v-if="tab == 'teams'"
      />
      <q-btn
        label="Atrás"
        icon="arrow_back"
        rounded
        color="amber-7"
        flat
        class="df-font w700 q-mb-lg"
        size="sm"
        @click="tab = 'teams'"
        v-else
      />
      <q-space />
      <q-btn
        label="Continuar"
        icon-right="arrow_forward"
        rounded
        color="amber-7"
        unelevated
        class="df-font w700 q-mb-lg"
        size="sm"
        @click="tab = 'myTeam'"
        v-if="tab == 'teams'"
      />
      <q-btn
        label="Continuar"
        icon-right="arrow_forward"
        rounded
        color="amber-7"
        unelevated
        class="df-font w700 q-mb-lg"
        size="sm"
        @click="validateRosterRules()"
        v-else
      />
    </div>

    <div class="text-h5 text-amber-7 text-center df-font w700i q-mb-lg">
      Selecciona tu roster
    </div>
    <div v-if="tab == 'teams'">
      <!-- <div class="row items-center">
        <q-icon
          name="done"
          size="1.4em"
          color="green-7"
          class="q-mr-sm"
          v-if="newTeamData.roster.length == fantasyData.rules.totalPlayers"
        />
        <q-icon
          name="close"
          size="1.4em"
          color="red-7"
          class="q-mr-sm"
          v-else
        />
        <div class="text-subtitle2 text-white w700i">
          Selecciona {{ fantasyData.rules.totalPlayers }} jugadores.
        </div>
      </div> -->
      <template v-if="fantasyData.rules.isGenderMix">
        <div class="row items-center">
          <q-icon
            name="done"
            size="1.4em"
            color="green-7"
            class="q-mr-sm"
            v-if="countPlayersByGender('m') == fantasyData.rules.maxMalePlayers"
          />
          <q-icon
            name="close"
            size="1.4em"
            color="red-7"
            class="q-mr-sm"
            v-else
          />
          <div class="text-subtitle2 text-white w700i">
            Selecciona {{ fantasyData.rules.maxMalePlayers }} jugadores
            masculinos. ({{ countPlayersByGender("m") }}/{{
              fantasyData.rules.maxMalePlayers
            }})
          </div>
        </div>
        <div class="row items-center">
          <q-icon
            name="done"
            size="1.4em"
            color="green-7"
            class="q-mr-sm"
            v-if="
              countPlayersByGender('f') == fantasyData.rules.maxFemalePlayers
            "
          />
          <q-icon
            name="close"
            size="1.4em"
            color="red-7"
            class="q-mr-sm"
            v-else
          />
          <div class="text-subtitle2 text-white w700i">
            Selecciona {{ fantasyData.rules.maxFemalePlayers }} jugadores
            femeninos. ({{ countPlayersByGender("f") }}/{{
              fantasyData.rules.maxFemalePlayers
            }})
          </div>
        </div>
      </template>
      <div class="row items-center">
        <q-icon
          name="done"
          size="1.4em"
          color="green-7"
          class="q-mr-sm"
          v-if="newTeamData.roster.length > 0 && countPlayertsByTeam() == 0"
        />
        <q-icon
          name="close"
          size="1.4em"
          color="red-7"
          class="q-mr-sm"
          v-else
        />
        <div class="text-subtitle2 text-white w700i">
          Máximo {{ fantasyData.rules.maxPlayersPerTeam }} jugadores de cada
          equipo.
        </div>
      </div>
    </div>
    <div v-if="tab == 'myTeam'">
      <div class="row items-center" v-if="fantasyData.rules.captainSelect">
        <q-icon
          name="done"
          size="1.4em"
          color="green-7"
          class="q-mr-sm"
          v-if="captainAlreadySelected"
        />
        <q-icon
          name="close"
          size="1.4em"
          color="red-7"
          class="q-mr-sm"
          v-else
        />
        <div class="text-subtitle2 text-white w700i">
          Selecciona un capitán.
        </div>
      </div>
      <div
        class="row items-center"
        v-for="role in fantasyData.roles"
        :key="role.value"
      >
        <q-icon
          name="done"
          size="1.4em"
          color="green-7"
          class="q-mr-sm"
          v-if="countPlayersByRole(role.value) == role.max"
        />
        <q-icon
          name="close"
          size="1.4em"
          color="red-7"
          class="q-mr-sm"
          v-else
        />
        <div class="text-subtitle2 text-white w700i">
          Selecciona {{ role.max }} jugadores "{{ role.label }}".
        </div>
      </div>
    </div>

    <q-input
      label="Buscar equipo"
      v-model="searchFilter"
      rounded
      standout="bg-amber-7"
      label-color="white"
      color="grey-6"
      dark
      class="q-my-lg"
      v-if="tab == 'teams'"
    />
    <q-input
      label="Buscar jugador"
      v-model="playerSearchFilter"
      rounded
      standout="bg-amber-7"
      label-color="white"
      color="grey-6"
      dark
      class="q-my-lg"
      v-if="tab == 'myTeam'"
    />

    <q-card style="border-radius: 20px;" class="q-mb-md">
      <q-tabs
        v-model="tab"
        dense
        class="text-grey"
        active-color="amber-7"
        indicator-color="amber-7"
        align="justify"
        narrow-indicator
      >
        <q-tab name="teams" label="Equipos" />
        <q-tab
          name="myTeam"
          :label="`Mis jugadores (${newTeamData.roster.length})`"
        />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="teams">
          <q-list separator>
            <q-item v-if="loadingTeams">
              <div class="row justify-center full-width q-py-lg">
                <q-spinner-dots color="amber-7" size="2.5em" />
              </div>
            </q-item>
            <q-item
              clickable
              v-ripple
              v-for="team in filterTeamsByName"
              :key="team.id"
              @click="
                $router.push(
                  `/team-roster-selector/${$route.params.fantasyId}/${team.id}`
                )
              "
            >
              <q-item-section avatar>
                <q-avatar>
                  <img
                    v-if="team.logo.attributes"
                    :src="team.logo.attributes.url"
                  />
                  <img v-else :src="team.logo" />
                </q-avatar>
              </q-item-section>
              <q-item-section>{{ team.name }}</q-item-section>
              <q-item-section avatar>
                <q-icon color="amber-7" name="chevron_right" />
              </q-item-section>
            </q-item>
          </q-list>
        </q-tab-panel>

        <q-tab-panel name="myTeam" class="q-px-none">
          <q-list v-if="newTeamData.roster.length > 0" separator>
            <q-item v-for="player in filterRosterByPlayerName" :key="player.id">
              <q-item-section avatar>
                <q-avatar>
                  <img
                    v-if="player.teamLogo.attributes"
                    :src="player.teamLogo.attributes.url"
                  />
                  <img v-else :src="player.teamLogo" />
                </q-avatar>
              </q-item-section>
              <q-item-section>
                <div class="row items-center q-mb-sm">
                  <template v-if="fantasyData.rules.isGenderMix">
                    <q-icon
                      name="male"
                      size="1.5em"
                      class="q-mr-sm"
                      color="blue-6"
                      v-if="player.gender == 'm'"
                    />
                    <q-icon
                      name="female"
                      size="1.5em"
                      class="q-mr-sm"
                      color="pink-6"
                      v-if="player.gender == 'f'"
                    />
                  </template>
                  <span class="df-font w700 text-subtitle2">{{
                    player.name
                  }}</span>
                  <span
                    class="text-caption text-amber-7 w700i"
                    v-if="player.isRookie && fantasyData.rules.rookieModifier"
                  >
                    &nbsp;Rookie</span
                  >
                </div>
                <div class="q-mb-sm">
                  <q-btn-toggle
                    v-model="player.role"
                    toggle-color="amber-7"
                    :options="fantasyData.roles"
                    emit-value
                    map-options
                    unelevated
                    rounded
                    spread
                    size="xs"
                    class="q-py-xs"
                  />
                </div>
                <div class="row">
                  <q-checkbox
                    v-model="player.isCaptain"
                    label="Capitan"
                    color="amber-7"
                    size="xs"
                    v-if="fantasyData.rules.captainSelect"
                    :disable="captainAlreadySelected && !player.isCaptain"
                  />
                </div>
              </q-item-section>
              <q-item-section avatar>
                <q-btn
                  icon="remove"
                  round
                  unelevated
                  color="red-7"
                  size="xs"
                  class="q-mr-md"
                  @click="removePlayerToNewTeamRoster(player)"
                />
              </q-item-section>
            </q-item>
          </q-list>
          <div class="text-subtitle2 text-center text-grey-6 q-py-xl" v-else>
            Aun no has agregado jugadores a tu equipo.
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
    <q-btn
      :label="fantasyData.rules.mainTeamSelect ? 'Continuar' : 'Finalizar'"
      color="amber-7"
      rounded
      class="df-font w700 full-width"
      push
      v-if="tab == 'myTeam'"
      @click="validateRosterRules()"
    />
    <BackToHome />
  </q-page>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BackToHome from "@/components/BackToHome";

import firebase from "firebase/app";
import "firebase/functions";

export default {
  data() {
    return {
      teams: [],
      searchFilter: "",
      playerSearchFilter: "",
      tab: "teams",
      fantasyData: null,
      loadingTeams: false,
    };
  },
  methods: {
    ...mapActions("appStore", [
      "removePlayerToNewTeamRoster",
      "storeNewTeam",
      "storeTeams",
    ]),

    validateRosterRules() {
      if (
        this.newTeamData.roster.length != this.fantasyData.rules.totalPlayers
      ) {
        this.displayError(
          `Tu selección debe ser de ${this.fantasyData.rules.totalPlayers} jugadores.`
        );
        return;
      }
      if (
        this.fantasyData.rules.isGenderMix &&
        (this.countPlayersByGender("m") >
          this.fantasyData.rules.maxMalePlayers ||
          this.countPlayersByGender("f") >
            this.fantasyData.rules.maxFemalePlayers)
      ) {
        this.displayError(
          `Debes seleccionar ${this.fantasyData.rules.maxMalePlayers} jugadores masculinos y ${this.fantasyData.rules.maxFemalePlayers} femeninos.`
        );
        return;
      }
      if (
        this.fantasyData.rules.captainSelect &&
        !this.captainAlreadySelected
      ) {
        this.displayError("Debes elegir un capitan de equipo.");
        return;
      }
      if (this.countPlayertsByTeam() > 0) {
        this.displayError(
          `Solo puedes elegir ${this.fantasyData.rules.maxPlayersPerTeam} jugadores por equipo.`
        );
        return;
      }
      let err = false;
      this.fantasyData.roles.forEach((role) => {
        if (this.countPlayersByRole(role.value) > role.max && !err) {
          err = true;
          this.displayError(
            `Solo puedes asignar ${role.max} jugadores en rol de "${role.label}"`
          );
        }
      });
      if (this.countPlayersWithoutRole() > 0) {
        this.displayError("No puedes dejar jugadores sin rol.");
        return;
      }
      if (!err) {
        if (this.fantasyData.rules.mainTeamSelect)
          this.$router.push(
            `/main-team-select/${this.$route.params.fantasyId}`
          );
        else {
          this.storeNewTeam();
        }
      }
    },
    countPlayersByGender(gender) {
      return this.newTeamData.roster.filter((player) => player.gender == gender)
        .length;
    },
    countPlayersByRole(role) {
      return this.newTeamData.roster.filter((player) => player.role == role)
        .length;
    },
    countPlayersWithoutRole() {
      return this.newTeamData.roster.filter((player) => !player.role).length;
    },
    countPlayertsByTeam() {
      let teams = [];
      let teamsPlayersAmount = [];
      this.newTeamData.roster.forEach((player) => {
        if (!teams.includes(player.teamId)) teams.push(player.teamId);
      });
      teams.forEach((team) => {
        teamsPlayersAmount.push({
          team: team,
          amount: this.newTeamData.roster.filter(
            (player) => player.teamId == team
          ).length,
        });
      });
      let teamsWithMorePlayersThanMax = teamsPlayersAmount.filter(
        (team) => team.amount > this.fantasyData.rules.maxPlayersPerTeam
      ).length;
      return teamsWithMorePlayersThanMax;
    },
    displayError(message) {
      this.$q.notify({
        message: message,
        color: "red-6",
        icon: "error",
        position: "top",
        badgeColor: "amber-6",
        progress: true,
        classes: "df-font w700i higher-toast",
        timeout: 3000,
      });
    },
  },
  computed: {
    ...mapState("appStore", ["newTeamData", "allFantasies"]),

    filterTeamsByName() {
      return this.teams.filter((team) =>
        team.name.toLowerCase().includes(this.searchFilter.toLowerCase())
      );
    },
    filterRosterByPlayerName() {
      return this.newTeamData.roster.filter((player) =>
        player.name
          .toLowerCase()
          .includes(this.playerSearchFilter.toLowerCase())
      );
    },
    captainAlreadySelected() {
      if (this.newTeamData.roster.find((player) => player.isCaptain))
        return true;
    },
  },
  components: {
    BackToHome,
  },
  async mounted() {
    window.scrollTo(0, 0);
    if (!this.newTeamData.name || this.allFantasies.length <= 0)
      this.$router.push(`/fantasy/${this.$route.params.fantasyId}`);

    this.fantasyData = this.allFantasies.find(
      (fantasy) => fantasy.id == this.$route.params.fantasyId
    );
    if (
      this.fantasyData.jsonTeams.includes("firebase") ||
      this.fantasyData.jsonTeams.includes("digitalocean")
    ) {
      this.loadingTeams = true;
      const getTeams = firebase.functions().httpsCallable("getTeamsJson");
      let teams = await getTeams(this.fantasyData.jsonTeams);
      this.teams = teams.data.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
      this.storeTeams(this.teams);
      this.loadingTeams = false;
    } else {
      try {
        let jsonTeams = require(`@/assets/${this.fantasyData.jsonTeams}`).teams;
        let teams = [];
        for (const i in jsonTeams) {
          jsonTeams[i].id = i;
          teams.push(jsonTeams[i]);
        }
        this.teams = teams.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
      } catch (err) {}
    }
  },
};
</script>
