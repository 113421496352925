<template>
  <q-page class="img-bg img-main">
    <div class="absolute-center full-width q-px-lg">
      <div class="row justify-center items-center q-mb-lg">
        <img src="@/assets/DEM_White_PNG.png" width="80" />
        <div class="text-h5 w700i text-amber-7 q-ml-xs">Fantasy</div>
      </div>

      <q-input
        label="Correo electrónico"
        rounded
        standout
        color="amber-7"
        label-color="amber-7"
        class="q-mb-md"
        v-model="loginData.email"
        dark
        type="email"
      />
      <q-input
        label="Contraseña"
        rounded
        class="q-mb-md"
        standout
        color="amber-7"
        label-color="amber-7"
        dark
        type="password"
        v-model="loginData.password"
      />
      <q-btn
        label="Iniciar sesion"
        no-caps
        class="w700i full-width q-mb-lg"
        color="amber-7"
        @click="login()"
        rounded
      />
      <div class="row q-mb-lg">
        <router-link to="/" class="text-white w700">Inicio</router-link>
        <q-space />
        <router-link to="/register" class="text-white w700"
          >Regístrate</router-link
        >
      </div>
      <div class="row text-center">
        <router-link to="/password-reset" class="text-white full-width"
          >Olvide mi contraseña</router-link
        >
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loginData: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions("appStore", ["loginUser"]),

    login() {
      if (!this.loginData.email || !this.loginData.password) {
        this.$q.notify({
          message: "Please insert email and password",
          color: "red-6",
          icon: "error",
          badgeColor: "amber-6",
          progress: true,
          classes: "df-font w700i higher-toast",
          timeout: 2000,
        });
      } else this.loginUser(this.loginData);
    },
  },
  computed: {
    returnRandomImg() {
      let min = Math.ceil(1);
      let max = Math.floor(6);
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
  },
};
</script>
