<template>
  <q-layout view="lHh Lpr lFf" id="app">
    <loading-dialog :show="loadingStatus" />
    <q-header
      elevated
      v-if="$route.fullPath != '/' && $route.fullPath != '/login'"
    >
      <q-toolbar class="bg-grey-10">
        <!-- <q-btn flat dense round to="/" aria-label="Home" icon="fas fa-home" /> -->
        <q-toolbar-title class="w700i text-center">
          <div class="row justify-center items-center">
            <img src="@/assets/DEM_White_PNG.png" width="50" />
            <!-- <div class="text-h6 w700i text-amber-7 q-ml-sm">Fantasy</div> -->
          </div>
        </q-toolbar-title>
        <!-- <q-btn
          flat
          round
          dense
          icon="fas fa-trophy"
          @click="trophyBtnAction()"
        /> -->
      </q-toolbar>
    </q-header>
    <q-page-container>
      <div class="row">
        <q-space />
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <router-view />
        </div>
        <q-space />
      </div>
    </q-page-container>
  </q-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import firebase from "firebase/app";
import "firebase/firebase-auth";
import { Platform } from "quasar";

export default {
  methods: {
    ...mapActions("appStore", ["logoutUser", "getUserData"]),

    trophyBtnAction() {
      if (this.$route.fullPath != "/ranking") this.$router.push("/ranking");
    },
  },
  computed: {
    ...mapState("appStore", ["loadingStatus", "userData"]),
  },
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) this.getUserData(user.uid);
    });
    this.$analytics.logEvent(`open-in-${Platform.is.platform}`);
  },
};
</script>

<style>
@import "./styles/style.css";
</style>
